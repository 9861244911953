<script lang="ts" setup>
import Tag from "primevue/tag";
import { computed } from "vue";

import { CAMPAIGN_TYPE_OPTIONS, ROAS_TYPES } from "@/constants";
import type { CampaignRoasType, CampaignType } from "@/graphql";

const props = defineProps<{
  campaignType: CampaignType;
  campaignRoasType?: CampaignRoasType | null;
}>();

const campaignTypeInfo = computed(() => {
  if (props.campaignRoasType) {
    return Object.values(ROAS_TYPES).find(type => type.value === props.campaignRoasType);
  }

  return CAMPAIGN_TYPE_OPTIONS.find(type => type.type === props.campaignType);
});
</script>

<template>
  <Tag
    v-if="campaignTypeInfo"
    v-tooltip="{ value: campaignTypeInfo.title }"
    :value="campaignTypeInfo.tagLabel"
    class="campaign-type-tag-component rounded-xl px-2"
    :style="{ '--color': campaignTypeInfo.color, '--backgroundColor': campaignTypeInfo.backgroundColor }"
  />
</template>

<style scoped lang="scss">
.campaign-type-tag-component {
  background-color: var(--backgroundColor, var(--primary-color));
  color: var(--color, white);
}
</style>
