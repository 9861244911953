import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { type UseQueryOptions, type UseQueryReturnType, useQuery } from "@tanstack/vue-query";
import type { OperationDefinitionNode } from "graphql";
import { watch } from "vue";

/**
 *
 * buildQueryKey: Build a query key for useQuery
 */
export function buildQueryKey<Q, V>(query: TypedDocumentNode<Q, V>): [string];
export function buildQueryKey<Q, V, T>(query: TypedDocumentNode<Q, V>, variables: T): [string, T];

export function buildQueryKey(query: TypedDocumentNode, variables?: unknown): unknown {
  const opDefinition = query.definitions.find(o => o.kind === "OperationDefinition") as OperationDefinitionNode;
  const queryName = opDefinition?.name?.value;
  if (queryName == null || queryName === "") {
    throw new Error("No query name found");
  }
  if (variables === undefined) {
    return [queryName];
  }
  return [queryName, variables];
}

/**
 * buildQuery: Returns the result of tanstack's useQuery with some helpers.
 */
export function buildQuery<TData, TError>(
  useQueryOption: UseQueryOptions<TData, TError>,
): UseQueryReturnType<TData, TError> & {
  onResult: (fn: (data: NonNullable<TData>) => void, options?: { immediate: boolean }) => void;
} {
  const result = useQuery(useQueryOption);

  const onResult = (fn: (data: NonNullable<TData>) => void): void => {
    watch(
      [result.isSuccess, result.isRefetching],
      ([isSuccess]) => {
        if (isSuccess && result.data.value != null) {
          fn(result.data.value);
        }
      },
      { immediate: true },
    );
  };

  return { ...result, onResult };
}
