import type { MaybeRef } from "@vueuse/core";
import type { MenuItem } from "primevue/menuitem";
import { type ComputedRef, type Ref, computed, ref, unref } from "vue";

import { useCampaignStatusArchiveCampaignMutation } from "./archive-campaign.mutation.graphql";
import { useCampaignStatusStartCampaignMutation } from "./start-campaign.mutation.graphql";
import { useCampaignStatusStopCampaignMutation } from "./stop-campaign.mutation.graphql";

import useConfirmMessage from "@/composables/use-confirm-messages";
import { useMutationRequest } from "@/graphql";

type UseCampaignStatus = {
  start: () => Promise<void>;
  stop: () => Promise<void>;
  archive: () => Promise<void>;
  isSuccess: Ref<boolean>;
  isLoading: ComputedRef<boolean>;
  startMenuItem: MenuItem;
  stopMenuItem: MenuItem;
  archiveMenuItem: MenuItem;
};

export function useCampaignStatus({ campaignId }: { campaignId: MaybeRef<string> }): UseCampaignStatus {
  const isSuccess = ref(false);

  const { confirmInfo, confirmWarning, confirmDanger } = useConfirmMessage();

  const { mutateAsync: startCampaign, isPending: isStarting } = useCampaignStatusStartCampaignMutation();
  const { mutateAsync: stopCampaign, isPending: isStopping } = useCampaignStatusStopCampaignMutation();
  const { mutateAsync: archiveCampaign, isPending: isArchiving } = useCampaignStatusArchiveCampaignMutation();

  const start = async () => {
    const confirmed = await confirmInfo(
      "Are you sure you want to start this campaign?",
    );

    isSuccess.value = false;

    if (confirmed) {
      const request = () => startCampaign({ campaignId: unref(campaignId) });
      const data = await useMutationRequest(request);
      isSuccess.value = data.success;
    }
  };

  const stop = async () => {
    const confirmed = await confirmWarning(
      "Are you sure you want to stop this campaign?",
    );

    isSuccess.value = false;

    if (confirmed) {
      const request = () => stopCampaign({ campaignId: unref(campaignId) });
      const data = await useMutationRequest(request);
      isSuccess.value = data.success;
    }
  };

  const archive = async () => {
    const confirmed = await confirmDanger(
      "You are about to archive a campaign. You will not be able to re-launch it",
    );

    isSuccess.value = false;

    if (confirmed) {
      const request = () => archiveCampaign({ campaignId: unref(campaignId) });
      const data = await useMutationRequest(request);
      isSuccess.value = data.success;
    }
  };

  const isLoading = computed(
    () => isStarting.value || isStopping.value || isArchiving.value,
  );

  const startMenuItem = {
    label: "Start",
    icon: "fa-regular fa-circle-play text-green-600",
    command: () => {
      void start();
    },
  } satisfies MenuItem;

  const stopMenuItem = {
    label: "Stop",
    icon: "fa-regular fa-circle-stop text-red-600",
    command: () => {
      void stop();
    },
  } satisfies MenuItem;

  const archiveMenuItem = {
    label: "Archive",
    icon: "fa-regular fa-box-archive",
    command: () => {
      void archive();
    },
  } satisfies MenuItem;

  return {
    start,
    stop,
    archive,
    isSuccess,
    isLoading,
    startMenuItem,
    stopMenuItem,
    archiveMenuItem,
  };
}
