<script setup lang="ts">
import Button from "primevue/button";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import CampaignsListTable from "./components/CampaignsListTable/CampaignsListTable.vue";
import { CAMPAIGNS_LIST_PAGE_ID } from "./constants";
import { useColumns } from "./use-columns";
import { useData } from "./use-data";
import { useFilters } from "./use-filters";

import ColumnsCustomizer from "@/components/business/ColumnsCustomizer/ColumnsCustomizer.vue";
import ListFilters from "@/components/business/ListFilters/ListFilters.vue";
import ReportingDateRangeCalendar from "@/components/business/ReportingDateRangeCalendar/ReportingDateRangeCalendar.vue";
import VdButton from "@/components/structures/VdButton/VdButton.vue";
import VdEmpty from "@/components/structures/VdEmpty/VdEmpty.vue";
import VdPageHeadline from "@/components/structures/VdPageHeadline/VdPageHeadline.vue";
import VdQueryStatus from "@/components/structures/VdQueryStatus/VdQueryStatus.vue";
import useReportingDateRange from "@/composables/use-reporting-daterange";
import { type AppFragment, CampaignStatus } from "@/graphql";
import links from "@/router/links";

const router = useRouter();

/**
 * Columns
 */
const displayColumnPicker = ref(false);
const { selectedColumns, customizableColumns } = useColumns();

/**
 * Filters
 */
const { filters, config: configFilters, clear: onClearFilters, filterRows } = useFilters();

/**
 * Queries
 */
const reportingDateRange = useReportingDateRange();

const showArchived = computed(() =>
  (filters.value.status.value ?? []).includes(CampaignStatus.Archived),
);

const {
  rows,
  isCampaignsLoading,
  campaignsError,
  isReportsLoading,
  isReportsError,
} = useData({ reportingDateRange, showArchived });

const filteredRows = computed(() =>
  filterRows(rows.value),
);

/**
 * Others
 */

function onCreateCampaign() {
  router.push({ name: links.campaigns.new });
}

// TODO: deprecated - use metaApps instead
const appOptions = computed(() => {
  const duplicatedApps = rows.value.map(row => row.app);
  const dedupApps = duplicatedApps
    .filter(Boolean)
    .filter(
      (app, index, all) => all.findIndex(o => o.id === app.id) === index,
    );
  return dedupApps as AppFragment[];
});
</script>

<template>
  <div class="campaigns-list-page" data-test="campaigns-list">
    <VdQueryStatus
      :data="rows"
      :error="campaignsError"
      :loading="isCampaignsLoading"
    >
      <VdPageHeadline title="Performance campaigns" class="mb-8">
        <template #end>
          <VdButton
            data-test="new-campaign"
            responsive-icon
            label="New campaign"
            icon="fa fa-plus"
            @click="onCreateCampaign"
          />
        </template>
      </VdPageHeadline>

      <div class="white-box layout-box flex flex-col gap-4">
        <ListFilters
          v-model:filters="filters"
          :config-filters="configFilters"
          :loading="isCampaignsLoading"
          :context="{ appOptions }"
          :storage-id="CAMPAIGNS_LIST_PAGE_ID"
          @clear="onClearFilters"
        />

        <CampaignsListTable
          :rows="rows"
          :filtered-rows="filteredRows"
          :selected-columns="selectedColumns"
          :is-loading="isCampaignsLoading"
          :is-reports-loading="isReportsLoading"
          :is-reports-error="isReportsError"
        >
          <template #empty>
            <VdEmpty title="no campaign">
              <p>You don't have created any campaign yet.</p>
              <template #actions>
                <Button
                  label="New campaign"
                  icon="fa fa-plus"
                  class="p-button-primary"
                  @click="onCreateCampaign"
                />
              </template>
            </VdEmpty>
          </template>

          <template #header>
            <div class="flex justify-between gap-2">
              <ReportingDateRangeCalendar />

              <Button
                v-tooltip.left="'Customize columns'"
                outlined
                icon="fa-duotone fa-line-columns"
                @click="displayColumnPicker = true"
              />
            </div>
          </template>
        </CampaignsListTable>
      </div>

      <ColumnsCustomizer
        v-model:visible="displayColumnPicker"
        v-model="selectedColumns"
        :columns="customizableColumns"
      />
    </VdQueryStatus>
  </div>
</template>
