<script setup lang="ts">
import Button from "primevue/button";
import Menu from "primevue/menu";
import type { MenuItem } from "primevue/menuitem";
import { computed, reactive, ref } from "vue";
import { RouterView } from "vue-router";

import AppMenu from "@/components/business/AppMenu/AppMenu.vue";
import CreateAssetsWatcher from "@/components/business/CreateAssetsWatcher/CreateAssetsWatcher.vue";
import useAuthentication from "@/composables/use-authentication";
import links from "@/router/links";

const { user, logout } = useAuthentication();

const menu = ref();

const items = reactive<MenuItem[]>([
  {
    label: "Performance campaigns",
    icon: "fa fa-megaphone",
    to: links.campaigns.list,
  },
  {
    label: "Branding campaigns",
    icon: "fa-solid fa-tv-retro",
    to: links.brandingCampaigns.list,
  },
  {
    label: "Assets",
    icon: "fa fa-folder-image",
    to: links.assets.list,
  },
  {
    label: "Apps",
    icon: "fa-thin fa-rectangle-history",
    to: links.apps.list,
  },
]);

const userMenuItems = computed<MenuItem[]>(() => [
  {
    label: user.value?.email,
    disabled: true,
  },
  { separator: true },
  {
    label: "Logout",
    command: () => {
      logout();
    },
  },
]);
</script>

<template>
  <div class="root-page z-0 flex h-screen w-full flex-col overflow-hidden sm:flex-row">
    <AppMenu :items="items">
      <template #end>
        <div class="flex items-center gap-4">
          <div>
            <Button
              icon="pi pi-user"
              class="p-button-secondary p-button-rounded p-button-outlined"
              aria-controls="user_menu"
              @click="(e) => menu.toggle(e)"
            />
            <Menu id="user_menu" ref="menu" class="w-56" :model="userMenuItems" popup />
          </div>
        </div>
      </template>
    </AppMenu>
    <div
      class="router-container relative flex flex-1 flex-col overflow-y-auto p-2 pt-8 sm:p-4 sm:pb-2 sm:pt-8 md:p-8 md:pb-2"
    >
      <div class="flex flex-1 flex-col">
        <div class="flex flex-1 flex-col">
          <div>
            <RouterView />
          </div>
        </div>
        <CreateAssetsWatcher />
        <footer class="root-page-footer w-full px-6 pt-12 sm:px-0">
          <div class="text-color-secondary">
            Copyright @Voodoo
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
