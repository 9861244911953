export type CountryOption = {
  name: string;
  alpha2: string;
  alpha3: string;
  region: string;
  subregion: string | null;
  tier: string;
};

export const ALL_COUNTRIES: CountryOption[] = [
  {
    name: "Afghanistan",
    alpha2: "AF",
    alpha3: "AFG",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 6",
  },
  {
    name: "Åland Islands",
    alpha2: "AX",
    alpha3: "ALA",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 6",
  },
  {
    name: "Albania",
    alpha2: "AL",
    alpha3: "ALB",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 5",
  },
  {
    name: "Algeria",
    alpha2: "DZ",
    alpha3: "DZA",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 4",
  },
  {
    name: "American Samoa",
    alpha2: "AS",
    alpha3: "ASM",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Andorra",
    alpha2: "AD",
    alpha3: "AND",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 6",
  },
  {
    name: "Angola",
    alpha2: "AO",
    alpha3: "AGO",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Anguilla",
    alpha2: "AI",
    alpha3: "AIA",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Antarctica",
    alpha2: "AQ",
    alpha3: "ATA",
    region: "Antarctica",
    subregion: null,
    tier: "Tier 6",
  },
  {
    name: "Antigua and Barbuda",
    alpha2: "AG",
    alpha3: "ATG",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Argentina",
    alpha2: "AR",
    alpha3: "ARG",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 3",
  },
  {
    name: "Armenia",
    alpha2: "AM",
    alpha3: "ARM",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 5",
  },
  {
    name: "Aruba",
    alpha2: "AW",
    alpha3: "ABW",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Australia",
    alpha2: "AU",
    alpha3: "AUS",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    tier: "Tier 1",
  },
  {
    name: "Austria",
    alpha2: "AT",
    alpha3: "AUT",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 3",
  },
  {
    name: "Azerbaijan",
    alpha2: "AZ",
    alpha3: "AZE",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 4",
  },
  {
    name: "Bahamas",
    alpha2: "BS",
    alpha3: "BHS",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Bahrain",
    alpha2: "BH",
    alpha3: "BHR",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 5",
  },
  {
    name: "Bangladesh",
    alpha2: "BD",
    alpha3: "BGD",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 5",
  },
  {
    name: "Barbados",
    alpha2: "BB",
    alpha3: "BRB",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Belarus",
    alpha2: "BY",
    alpha3: "BLR",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 4",
  },
  {
    name: "Belgium",
    alpha2: "BE",
    alpha3: "BEL",
    region: "Europe",
    subregion: "Western Europe",
    tier: "Tier 2",
  },
  {
    name: "Belize",
    alpha2: "BZ",
    alpha3: "BLZ",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 5",
  },
  {
    name: "Benin",
    alpha2: "BJ",
    alpha3: "BEN",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Bermuda",
    alpha2: "BM",
    alpha3: "BMU",
    region: "Americas",
    subregion: "Northern America",
    tier: "Tier 5",
  },
  {
    name: "Bhutan",
    alpha2: "BT",
    alpha3: "BTN",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 6",
  },
  {
    name: "Bolivia",
    alpha2: "BO",
    alpha3: "BOL",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 5",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2: "BQ",
    alpha3: "BES",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2: "BA",
    alpha3: "BIH",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 5",
  },
  {
    name: "Botswana",
    alpha2: "BW",
    alpha3: "BWA",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 5",
  },
  {
    name: "Bouvet Island",
    alpha2: "BV",
    alpha3: "BVT",
    region: "Antarctica",
    subregion: null,
    tier: "Tier 6",
  },
  {
    name: "Brazil",
    alpha2: "BR",
    alpha3: "BRA",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 3",
  },
  {
    name: "British Indian Ocean Territory",
    alpha2: "IO",
    alpha3: "IOT",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 6",
  },
  {
    name: "Brunei Darussalam",
    alpha2: "BN",
    alpha3: "BRN",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 5",
  },
  {
    name: "Bulgaria",
    alpha2: "BG",
    alpha3: "BGR",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 4",
  },
  {
    name: "Burkina Faso",
    alpha2: "BF",
    alpha3: "BFA",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Burundi",
    alpha2: "BI",
    alpha3: "BDI",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Cambodia",
    alpha2: "KH",
    alpha3: "KHM",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 5",
  },
  {
    name: "Cameroon",
    alpha2: "CM",
    alpha3: "CMR",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Canada",
    alpha2: "CA",
    alpha3: "CAN",
    region: "Americas",
    subregion: "Northern America",
    tier: "Tier 1",
  },
  {
    name: "Cabo Verde",
    alpha2: "CV",
    alpha3: "CPV",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Cayman Islands",
    alpha2: "KY",
    alpha3: "CYM",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Central African Republic",
    alpha2: "CF",
    alpha3: "CAF",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Chad",
    alpha2: "TD",
    alpha3: "TCD",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Chile",
    alpha2: "CL",
    alpha3: "CHL",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 4",
  },
  {
    name: "China",
    alpha2: "CN",
    alpha3: "CHN",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "China",
  },
  {
    name: "Christmas Island",
    alpha2: "CX",
    alpha3: "CXR",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    tier: "Tier 6",
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha2: "CC",
    alpha3: "CCK",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    tier: "Tier 6",
  },
  {
    name: "Colombia",
    alpha2: "CO",
    alpha3: "COL",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 4",
  },
  {
    name: "Comoros",
    alpha2: "KM",
    alpha3: "COM",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Congo",
    alpha2: "CG",
    alpha3: "COG",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Congo (RDC)",
    alpha2: "CD",
    alpha3: "COD",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Cook Islands",
    alpha2: "CK",
    alpha3: "COK",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Costa Rica",
    alpha2: "CR",
    alpha3: "CRI",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 4",
  },
  {
    name: "Croatia",
    alpha2: "HR",
    alpha3: "HRV",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 4",
  },
  {
    name: "Cuba",
    alpha2: "CU",
    alpha3: "CUB",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Curaçao",
    alpha2: "CW",
    alpha3: "CUW",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Cyprus",
    alpha2: "CY",
    alpha3: "CYP",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 5",
  },
  {
    name: "Czech Republic",
    alpha2: "CZ",
    alpha3: "CZE",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 3",
  },
  {
    name: "Denmark",
    alpha2: "DK",
    alpha3: "DNK",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 3",
  },
  {
    name: "Djibouti",
    alpha2: "DJ",
    alpha3: "DJI",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Dominica",
    alpha2: "DM",
    alpha3: "DMA",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Dominican Republic",
    alpha2: "DO",
    alpha3: "DOM",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 4",
  },
  {
    name: "Ecuador",
    alpha2: "EC",
    alpha3: "ECU",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 4",
  },
  {
    name: "Egypt",
    alpha2: "EG",
    alpha3: "EGY",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 4",
  },
  {
    name: "El Salvador",
    alpha2: "SV",
    alpha3: "SLV",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 5",
  },
  {
    name: "Equatorial Guinea",
    alpha2: "GQ",
    alpha3: "GNQ",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Eritrea",
    alpha2: "ER",
    alpha3: "ERI",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 6",
  },
  {
    name: "Estonia",
    alpha2: "EE",
    alpha3: "EST",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 5",
  },
  {
    name: "Ethiopia",
    alpha2: "ET",
    alpha3: "ETH",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 4",
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha2: "FK",
    alpha3: "FLK",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 6",
  },
  {
    name: "Faroe Islands",
    alpha2: "FO",
    alpha3: "FRO",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 6",
  },
  {
    name: "Fiji",
    alpha2: "FJ",
    alpha3: "FJI",
    region: "Oceania",
    subregion: "Melanesia",
    tier: "Tier 5",
  },
  {
    name: "Finland",
    alpha2: "FI",
    alpha3: "FIN",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 3",
  },
  {
    name: "France",
    alpha2: "FR",
    alpha3: "FRA",
    region: "Europe",
    subregion: "Western Europe",
    tier: "Tier 1",
  },
  {
    name: "French Guiana",
    alpha2: "GF",
    alpha3: "GUF",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 6",
  },
  {
    name: "French Polynesia",
    alpha2: "PF",
    alpha3: "PYF",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "French Southern Territories",
    alpha2: "TF",
    alpha3: "ATF",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 6",
  },
  {
    name: "Gabon",
    alpha2: "GA",
    alpha3: "GAB",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Gambia",
    alpha2: "GM",
    alpha3: "GMB",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Georgia",
    alpha2: "GE",
    alpha3: "GEO",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 5",
  },
  {
    name: "Germany",
    alpha2: "DE",
    alpha3: "DEU",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 1",
  },
  {
    name: "Ghana",
    alpha2: "GH",
    alpha3: "GHA",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Gibraltar",
    alpha2: "GI",
    alpha3: "GIB",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 6",
  },
  {
    name: "Greece",
    alpha2: "GR",
    alpha3: "GRC",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 3",
  },
  {
    name: "Greenland",
    alpha2: "GL",
    alpha3: "GRL",
    region: "Americas",
    subregion: "Northern America",
    tier: "Tier 6",
  },
  {
    name: "Grenada",
    alpha2: "GD",
    alpha3: "GRD",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Guadeloupe",
    alpha2: "GP",
    alpha3: "GLP",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Guam",
    alpha2: "GU",
    alpha3: "GUM",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 6",
  },
  {
    name: "Guatemala",
    alpha2: "GT",
    alpha3: "GTM",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 4",
  },
  {
    name: "Guernsey",
    alpha2: "GG",
    alpha3: "GGY",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 6",
  },
  {
    name: "Guinea",
    alpha2: "GN",
    alpha3: "GIN",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Guinea-Bissau",
    alpha2: "GW",
    alpha3: "GNB",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Guyana",
    alpha2: "GY",
    alpha3: "GUY",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 5",
  },
  {
    name: "Haiti",
    alpha2: "HT",
    alpha3: "HTI",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha2: "HM",
    alpha3: "HMD",
    region: "Antarctica",
    subregion: null,
    tier: "Tier 6",
  },
  {
    name: "Vatican City",
    alpha2: "VA",
    alpha3: "VAT",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 6",
  },
  {
    name: "Honduras",
    alpha2: "HN",
    alpha3: "HND",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 5",
  },
  {
    name: "Hungary",
    alpha2: "HU",
    alpha3: "HUN",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 3",
  },
  {
    name: "Hong Kong",
    alpha2: "HK",
    alpha3: "HKG",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Iceland",
    alpha2: "IS",
    alpha3: "ISL",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 5",
  },
  {
    name: "India",
    alpha2: "IN",
    alpha3: "IND",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 3",
  },
  {
    name: "Indonesia",
    alpha2: "ID",
    alpha3: "IDN",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Ivory Coast",
    alpha2: "CI",
    alpha3: "CIV",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Iran (Islamic Republic of)",
    alpha2: "IR",
    alpha3: "IRN",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 5",
  },
  {
    name: "Iraq",
    alpha2: "IQ",
    alpha3: "IRQ",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 5",
  },
  {
    name: "Ireland",
    alpha2: "IE",
    alpha3: "IRL",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 3",
  },
  {
    name: "Isle of Man",
    alpha2: "IM",
    alpha3: "IMN",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 6",
  },
  {
    name: "Israel",
    alpha2: "IL",
    alpha3: "ISR",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 3",
  },
  {
    name: "Italy",
    alpha2: "IT",
    alpha3: "ITA",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 2",
  },
  {
    name: "Jamaica",
    alpha2: "JM",
    alpha3: "JAM",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Japan",
    alpha2: "JP",
    alpha3: "JPN",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 1",
  },
  {
    name: "Jersey",
    alpha2: "JE",
    alpha3: "JEY",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 6",
  },
  {
    name: "Jordan",
    alpha2: "JO",
    alpha3: "JOR",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 4",
  },
  {
    name: "Kazakhstan",
    alpha2: "KZ",
    alpha3: "KAZ",
    region: "Asia",
    subregion: "Central Asia",
    tier: "Tier 5",
  },
  {
    name: "Kenya",
    alpha2: "KE",
    alpha3: "KEN",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 4",
  },
  {
    name: "Kiribati",
    alpha2: "KI",
    alpha3: "KIR",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 5",
  },
  {
    name: "Kuwait",
    alpha2: "KW",
    alpha3: "KWT",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 3",
  },
  {
    name: "Kyrgyzstan",
    alpha2: "KG",
    alpha3: "KGZ",
    region: "Asia",
    subregion: "Central Asia",
    tier: "Tier 5",
  },
  {
    name: "Laos",
    alpha2: "LA",
    alpha3: "LAO",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 5",
  },
  {
    name: "Latvia",
    alpha2: "LV",
    alpha3: "LVA",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 5",
  },
  {
    name: "Lebanon",
    alpha2: "LB",
    alpha3: "LBN",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 6",
  },
  {
    name: "Lesotho",
    alpha2: "LS",
    alpha3: "LSO",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 5",
  },
  {
    name: "Liberia",
    alpha2: "LR",
    alpha3: "LBR",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Libya",
    alpha2: "LY",
    alpha3: "LBY",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 5",
  },
  {
    name: "Liechtenstein",
    alpha2: "LI",
    alpha3: "LIE",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 6",
  },
  {
    name: "Lithuania",
    alpha2: "LT",
    alpha3: "LTU",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 4",
  },
  {
    name: "Luxembourg",
    alpha2: "LU",
    alpha3: "LUX",
    region: "Europe",
    subregion: "Western Europe",
    tier: "Tier 5",
  },
  {
    name: "Macao",
    alpha2: "MO",
    alpha3: "MAC",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 6",
  },
  {
    name: "North Macedonia",
    alpha2: "MK",
    alpha3: "MKD",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 5",
  },
  {
    name: "Madagascar",
    alpha2: "MG",
    alpha3: "MDG",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Malawi",
    alpha2: "MW",
    alpha3: "MWI",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Malaysia",
    alpha2: "MY",
    alpha3: "MYS",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Maldives",
    alpha2: "MV",
    alpha3: "MDV",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 5",
  },
  {
    name: "Mali",
    alpha2: "ML",
    alpha3: "MLI",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Malta",
    alpha2: "MT",
    alpha3: "MLT",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 5",
  },
  {
    name: "Marshall Islands",
    alpha2: "MH",
    alpha3: "MHL",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 5",
  },
  {
    name: "Martinique",
    alpha2: "MQ",
    alpha3: "MTQ",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Mauritania",
    alpha2: "MR",
    alpha3: "MRT",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Mauritius",
    alpha2: "MU",
    alpha3: "MUS",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Mayotte",
    alpha2: "YT",
    alpha3: "MYT",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 6",
  },
  {
    name: "Mexico",
    alpha2: "MX",
    alpha3: "MEX",
    region: "Americas",
    subregion: "North America",
    tier: "Tier 3",
  },
  {
    name: "Micronesia",
    alpha2: "FM",
    alpha3: "FSM",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 5",
  },
  {
    name: "Moldova",
    alpha2: "MD",
    alpha3: "MDA",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 5",
  },
  {
    name: "Monaco",
    alpha2: "MC",
    alpha3: "MCO",
    region: "Europe",
    subregion: "Western Europe",
    tier: "Tier 6",
  },
  {
    name: "Mongolia",
    alpha2: "MN",
    alpha3: "MNG",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 5",
  },
  {
    name: "Montenegro",
    alpha2: "ME",
    alpha3: "MNE",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 5",
  },
  {
    name: "Montserrat",
    alpha2: "MS",
    alpha3: "MSR",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Morocco",
    alpha2: "MA",
    alpha3: "MAR",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 4",
  },
  {
    name: "Mozambique",
    alpha2: "MZ",
    alpha3: "MOZ",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Myanmar",
    alpha2: "MM",
    alpha3: "MMR",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 5",
  },
  {
    name: "Namibia",
    alpha2: "NA",
    alpha3: "NAM",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 5",
  },
  {
    name: "Nauru",
    alpha2: "NR",
    alpha3: "NRU",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 5",
  },
  {
    name: "Nepal",
    alpha2: "NP",
    alpha3: "NPL",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 5",
  },
  {
    name: "Netherlands",
    alpha2: "NL",
    alpha3: "NLD",
    region: "Europe",
    subregion: "Western Europe",
    tier: "Tier 2",
  },
  {
    name: "New Caledonia",
    alpha2: "NC",
    alpha3: "NCL",
    region: "Oceania",
    subregion: "Melanesia",
    tier: "Tier 6",
  },
  {
    name: "New Zealand",
    alpha2: "NZ",
    alpha3: "NZL",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    tier: "Tier 3",
  },
  {
    name: "Nicaragua",
    alpha2: "NI",
    alpha3: "NIC",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 5",
  },
  {
    name: "Niger",
    alpha2: "NE",
    alpha3: "NER",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Nigeria",
    alpha2: "NG",
    alpha3: "NGA",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 4",
  },
  {
    name: "Niue",
    alpha2: "NU",
    alpha3: "NIU",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Norfolk Island",
    alpha2: "NF",
    alpha3: "NFK",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    tier: "Tier 6",
  },
  {
    name: "Northern Mariana Islands",
    alpha2: "MP",
    alpha3: "MNP",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 6",
  },
  {
    name: "Norway",
    alpha2: "NO",
    alpha3: "NOR",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 3",
  },
  {
    name: "Oman",
    alpha2: "OM",
    alpha3: "OMN",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 4",
  },
  {
    name: "Pakistan",
    alpha2: "PK",
    alpha3: "PAK",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 5",
  },
  {
    name: "Palau",
    alpha2: "PW",
    alpha3: "PLW",
    region: "Oceania",
    subregion: "Micronesia",
    tier: "Tier 6",
  },
  {
    name: "Palestine, State of",
    alpha2: "PS",
    alpha3: "PSE",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 5",
  },
  {
    name: "Panama",
    alpha2: "PA",
    alpha3: "PAN",
    region: "Americas",
    subregion: "Central America",
    tier: "Tier 5",
  },
  {
    name: "Papua New Guinea",
    alpha2: "PG",
    alpha3: "PNG",
    region: "Oceania",
    subregion: "Melanesia",
    tier: "Tier 5",
  },
  {
    name: "Paraguay",
    alpha2: "PY",
    alpha3: "PRY",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 5",
  },
  {
    name: "Peru",
    alpha2: "PE",
    alpha3: "PER",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 4",
  },
  {
    name: "Philippines",
    alpha2: "PH",
    alpha3: "PHL",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Pitcairn",
    alpha2: "PN",
    alpha3: "PCN",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Poland",
    alpha2: "PL",
    alpha3: "POL",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 2",
  },
  {
    name: "Portugal",
    alpha2: "PT",
    alpha3: "PRT",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 3",
  },
  {
    name: "Puerto Rico",
    alpha2: "PR",
    alpha3: "PRI",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 4",
  },
  {
    name: "Qatar",
    alpha2: "QA",
    alpha3: "QAT",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 3",
  },
  {
    name: "Republic of Kosovo",
    alpha2: "XK",
    alpha3: "UNK",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 6",
  },
  {
    name: "Réunion",
    alpha2: "RE",
    alpha3: "REU",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 6",
  },
  {
    name: "Romania",
    alpha2: "RO",
    alpha3: "ROU",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 3",
  },
  {
    name: "Russian Federation",
    alpha2: "RU",
    alpha3: "RUS",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 5",
  },
  {
    name: "Rwanda",
    alpha2: "RW",
    alpha3: "RWA",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Saint Barthélemy",
    alpha2: "BL",
    alpha3: "BLM",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2: "SH",
    alpha3: "SHN",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 6",
  },
  {
    name: "Saint Kitts and Nevis",
    alpha2: "KN",
    alpha3: "KNA",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Saint Lucia",
    alpha2: "LC",
    alpha3: "LCA",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Saint Martin (French part)",
    alpha2: "MF",
    alpha3: "MAF",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha2: "PM",
    alpha3: "SPM",
    region: "Americas",
    subregion: "Northern America",
    tier: "Tier 6",
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha2: "VC",
    alpha3: "VCT",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Samoa",
    alpha2: "WS",
    alpha3: "WSM",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 5",
  },
  {
    name: "San Marino",
    alpha2: "SM",
    alpha3: "SMR",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 6",
  },
  {
    name: "Sao Tome and Principe",
    alpha2: "ST",
    alpha3: "STP",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 5",
  },
  {
    name: "Saudi Arabia",
    alpha2: "SA",
    alpha3: "SAU",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 3",
  },
  {
    name: "Senegal",
    alpha2: "SN",
    alpha3: "SEN",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Serbia",
    alpha2: "RS",
    alpha3: "SRB",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 4",
  },
  {
    name: "Seychelles",
    alpha2: "SC",
    alpha3: "SYC",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Sierra Leone",
    alpha2: "SL",
    alpha3: "SLE",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Singapore",
    alpha2: "SG",
    alpha3: "SGP",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha2: "SX",
    alpha3: "SXM",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Slovakia",
    alpha2: "SK",
    alpha3: "SVK",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 4",
  },
  {
    name: "Slovenia",
    alpha2: "SI",
    alpha3: "SVN",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 4",
  },
  {
    name: "Solomon Islands",
    alpha2: "SB",
    alpha3: "SLB",
    region: "Oceania",
    subregion: "Melanesia",
    tier: "Tier 5",
  },
  {
    name: "Somalia",
    alpha2: "SO",
    alpha3: "SOM",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "South Africa",
    alpha2: "ZA",
    alpha3: "ZAF",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 4",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha2: "GS",
    alpha3: "SGS",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 6",
  },
  {
    name: "North Korea",
    alpha2: "KP",
    alpha3: "PRK",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 6",
  },
  {
    name: "South Korea",
    alpha2: "KR",
    alpha3: "KOR",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 1",
  },
  {
    name: "Spain",
    alpha2: "ES",
    alpha3: "ESP",
    region: "Europe",
    subregion: "Southern Europe",
    tier: "Tier 2",
  },
  {
    name: "Sri Lanka",
    alpha2: "LK",
    alpha3: "LKA",
    region: "Asia",
    subregion: "Southern Asia",
    tier: "Tier 5",
  },
  {
    name: "Sudan",
    alpha2: "SD",
    alpha3: "SDN",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 5",
  },
  {
    name: "South Sudan",
    alpha2: "SS",
    alpha3: "SSD",
    region: "Africa",
    subregion: "Middle Africa",
    tier: "Tier 6",
  },
  {
    name: "Suriname",
    alpha2: "SR",
    alpha3: "SUR",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 5",
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha2: "SJ",
    alpha3: "SJM",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 6",
  },
  {
    name: "Swaziland",
    alpha2: "SZ",
    alpha3: "SWZ",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 5",
  },
  {
    name: "Sweden",
    alpha2: "SE",
    alpha3: "SWE",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 2",
  },
  {
    name: "Switzerland",
    alpha2: "CH",
    alpha3: "CHE",
    region: "Europe",
    subregion: "Central Europe",
    tier: "Tier 2",
  },
  {
    name: "Syrian Arab Republic",
    alpha2: "SY",
    alpha3: "SYR",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 6",
  },
  {
    name: "Taiwan",
    alpha2: "TW",
    alpha3: "TWN",
    region: "Asia",
    subregion: "Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Tajikistan",
    alpha2: "TJ",
    alpha3: "TJK",
    region: "Asia",
    subregion: "Central Asia",
    tier: "Tier 5",
  },
  {
    name: "Tanzania",
    alpha2: "TZ",
    alpha3: "TZA",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Thailand",
    alpha2: "TH",
    alpha3: "THA",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Timor-Leste",
    alpha2: "TL",
    alpha3: "TLS",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 5",
  },
  {
    name: "Togo",
    alpha2: "TG",
    alpha3: "TGO",
    region: "Africa",
    subregion: "Western Africa",
    tier: "Tier 5",
  },
  {
    name: "Tokelau",
    alpha2: "TK",
    alpha3: "TKL",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Tonga",
    alpha2: "TO",
    alpha3: "TON",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Trinidad and Tobago",
    alpha2: "TT",
    alpha3: "TTO",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Tunisia",
    alpha2: "TN",
    alpha3: "TUN",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 5",
  },
  {
    name: "Turkey",
    alpha2: "TR",
    alpha3: "TUR",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 3",
  },
  {
    name: "Turkmenistan",
    alpha2: "TM",
    alpha3: "TKM",
    region: "Asia",
    subregion: "Central Asia",
    tier: "Tier 6",
  },
  {
    name: "Turks and Caicos Islands",
    alpha2: "TC",
    alpha3: "TCA",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 5",
  },
  {
    name: "Tuvalu",
    alpha2: "TV",
    alpha3: "TUV",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 5",
  },
  {
    name: "Uganda",
    alpha2: "UG",
    alpha3: "UGA",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Ukraine",
    alpha2: "UA",
    alpha3: "UKR",
    region: "Europe",
    subregion: "Eastern Europe",
    tier: "Tier 5",
  },
  {
    name: "United Arab Emirates (UAE)",
    alpha2: "AE",
    alpha3: "ARE",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 3",
  },
  {
    name: "United Kingdom (UK)",
    alpha2: "GB",
    alpha3: "GBR",
    region: "Europe",
    subregion: "Northern Europe",
    tier: "Tier 1",
  },
  {
    name: "USA (United States of America)",
    alpha2: "US",
    alpha3: "USA",
    region: "Americas",
    subregion: "Northern America",
    tier: "USA",
  },
  {
    name: "Uruguay",
    alpha2: "UY",
    alpha3: "URY",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 4",
  },
  {
    name: "Uzbekistan",
    alpha2: "UZ",
    alpha3: "UZB",
    region: "Asia",
    subregion: "Central Asia",
    tier: "Tier 5",
  },
  {
    name: "Vanuatu",
    alpha2: "VU",
    alpha3: "VUT",
    region: "Oceania",
    subregion: "Melanesia",
    tier: "Tier 5",
  },
  {
    name: "Venezuela",
    alpha2: "VE",
    alpha3: "VEN",
    region: "Americas",
    subregion: "South America",
    tier: "Tier 6",
  },
  {
    name: "Vietnam",
    alpha2: "VN",
    alpha3: "VNM",
    region: "Asia",
    subregion: "South-Eastern Asia",
    tier: "Tier 3",
  },
  {
    name: "Virgin Islands (British)",
    alpha2: "VG",
    alpha3: "VGB",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha2: "VI",
    alpha3: "VIR",
    region: "Americas",
    subregion: "Caribbean",
    tier: "Tier 6",
  },
  {
    name: "Wallis and Futuna",
    alpha2: "WF",
    alpha3: "WLF",
    region: "Oceania",
    subregion: "Polynesia",
    tier: "Tier 6",
  },
  {
    name: "Western Sahara",
    alpha2: "EH",
    alpha3: "ESH",
    region: "Africa",
    subregion: "Northern Africa",
    tier: "Tier 6",
  },
  {
    name: "Yemen",
    alpha2: "YE",
    alpha3: "YEM",
    region: "Asia",
    subregion: "Western Asia",
    tier: "Tier 6",
  },
  {
    name: "Zambia",
    alpha2: "ZM",
    alpha3: "ZMB",
    region: "Africa",
    subregion: "Eastern Africa",
    tier: "Tier 5",
  },
  {
    name: "Zimbabwe",
    alpha2: "ZW",
    alpha3: "ZWE",
    region: "Africa",
    subregion: "Southern Africa",
    tier: "Tier 5",
  },
];

/** https://www.tradecompliance.pitt.edu/embargoed-and-sanctioned-countries */
export const EMBARGOED_COUNTRIES = ["CUB", "IRN", "PRK", "SYR"];

/**
 * At the current date (sept. 2024), these countries represented less than 0.01% of our spend (ie. low impact regarding our scale)
 * and they generally bring in CPM <$1 cross network on Voodoo apps (ie. challenging profitability when factoring in our infra cost)
 */
const EXCLUDED_COUNTRIES = [
  "VNM",
  "COL",
  "ARG",
  "PER",
  "IDN",
  "PHL",
  "IND",
  "IRQ",
  "PAK",
  "PSE",
  "NGA",
  "BGD",
  "EGY",
  "MAR",
  "DZA",
  "LBY",
  "LBN",
  "GTM",
  "URY",
  "DOM",
  "UZB",
  "NPL",
  "JOR",
  "CMR",
  "CIV",
  "TUN",
  "KAZ",
  "PRY",
  "SEN",
  "MNG",
  "KGZ",
  "MKD",
  "MRT",
  "SWZ",
  "COD",
  "GNB",
  "VUT",
  "BOL",
  "HND",
  "MDG",
  "SDN",
  "SOM",
  "LKA",
  "SUR",
  "TGO",
  "BTN",
  "PAN",
  "NAM",
  "SLV",
  "GUY",
  "CPV",
  "WLF",
  "MHL",
  "BIH",
  "YEM",
  "GHA",
  "NER",
  "BLR",
  "TZA",
  "ARM",
  "AGO",
  "BWA",
  "GEO",
  "BEN",
  "MUS",
  "PNG",
  "SLB",
  "STP",
  "KHM",
  "UGA",
  "RWA",
  "GIN",
  "GMB",
  "FSM",
  "AZE",
  "AIA",
  "MWI",
  "SLE",
  "MOZ",
  "WSM",
  "TON",
  "ZMB",
  "LBR",
  "MMR",
  "VCT",
  "GAB",
  "AFG",
  "BLZ",
  "MLI",
  "VEN",
  "TCD",
  "CHN",
  "SSD",
  "TJK",
  "DJI",
  "CAF",
  "TLS",
  "BDI",
  "SYC",
  "LAO",
  "LSO",
  "COM",
  "KEN",
  "BFA",
  "ETH",
  "BRN",
  "ECU",
  "HTI",
  "COG",
  "FJI",
  "DMA",
  "LCA",
  "ZWE",
  "NIC",
  "MYT",
  "KIR",
  "GNQ",
  "TKM",
];

export const SUPPORTED_COUNTRIES: CountryOption[] = ALL_COUNTRIES.filter(
  country => !EXCLUDED_COUNTRIES.includes(country.alpha3) && !EMBARGOED_COUNTRIES.includes(country.alpha3),
);

const countryOptionByAlpha3Code = Object.fromEntries(ALL_COUNTRIES.map(country => [country.alpha3, country]));

const countryOptionByAlpha2Code = Object.fromEntries(ALL_COUNTRIES.map(country => [country.alpha2, country]));

const countryAlpha2CodesByAlpha3Code = Object.fromEntries(ALL_COUNTRIES.map(country => [country.alpha3, country.alpha2]));

export function getCountryInfo(countryAlpha2orAlpha3Code: string): CountryOption | null {
  return countryOptionByAlpha3Code[countryAlpha2orAlpha3Code] ?? countryOptionByAlpha2Code[countryAlpha2orAlpha3Code] ?? null;
}

export function getCountryName(countryAlpha2orAlpha3Code: string): string {
  return getCountryInfo(countryAlpha2orAlpha3Code)?.name ?? "";
}

export function getCountryAlpha2Code(countryAlpha3Code: string): string {
  return countryAlpha2CodesByAlpha3Code[countryAlpha3Code] || "";
}

export const ALL_COUNTRIES_ISO_ALPHA3_CODES = ALL_COUNTRIES.map(({ alpha3 }) => alpha3);
