import { type Ref, computed } from "vue";

import { useBrandingCampaignsListPageQuery } from "./queries/branding-campaigns.query.graphql";
import type { BrandingCampaignsListRow } from "./types";

import type { ReportingDateRange } from "@/composables/use-reporting-daterange";
import { type BrandingCampaignsListPageQueryVariables, CampaignStatus, type CampaignsListPageReportsQueryVariables } from "@/graphql";
import { extractDateFromDatetime } from "@/utils/common";

export function useData({ showArchived, reportingDateRange }: { showArchived: Ref<boolean>; reportingDateRange: ReportingDateRange }) {
  const brandingCampaignsQueryVariables = computed<BrandingCampaignsListPageQueryVariables>(() => ({
    showTestMode: true,
    sorts: "-creationDate",
    status: [
      CampaignStatus.Running,
      CampaignStatus.Pending,
      CampaignStatus.Stopped,
      CampaignStatus.Draft,
      ...(showArchived.value ? [CampaignStatus.Archived] : []),
    ],
  }));

  const {
    data: campaignsData,
    isPending: isCampaignsLoading,
    error: campaignsError,
  } = useBrandingCampaignsListPageQuery(brandingCampaignsQueryVariables);

  const campaigns = computed(() => campaignsData.value?.brandingCampaigns.edges.map(o => o.node) ?? []);

  const fetchedCampaignsIds = computed(() => campaigns.value.map(o => o.id));

  /**
   * Reports
   */

  const isReportingQueryEnabled = computed(
    () => fetchedCampaignsIds.value.length > 0,
  );

  const reportQueryVariables = computed<CampaignsListPageReportsQueryVariables>(() => ({
    campaignIds: fetchedCampaignsIds.value,
    startDate: extractDateFromDatetime(reportingDateRange.value.start),
    endDate: extractDateFromDatetime(reportingDateRange.value.end),
    today: extractDateFromDatetime(new Date()),
  }));

  const rows = computed<BrandingCampaignsListRow[]>(() =>
    campaigns.value.map(campaign => campaign),
  );

  return {
    rows,
    isCampaignsLoading,
    campaignsError,

    isReportingQueryEnabled, // TODO: use this inside the composable
    reportQueryVariables, // TODO: use this inside the composable
  };
}
