<script lang="ts" setup>
import {
  endOfToday,
  endOfYesterday,
  startOfDay,
  startOfToday,
  startOfYesterday,
  subDays,
} from "date-fns";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import { ref, watchEffect } from "vue";

import type { DateRangeEmittedValue, DateRangeValue } from "./types";

import { extractDateFromDatetime } from "@/utils/common";

const props = withDefaults(
  defineProps<{
    modelValue?: DateRangeValue | null;
    tooltipText?: string;
    isClearable?: boolean;
  }>(),
  {
    modelValue: null,
    tooltipText: "",
  },
);

const emit = defineEmits<{
  select: [value: DateRangeEmittedValue];
}>();

const model = ref<DateRangeValue>(props.modelValue ?? []);

watchEffect(() => {
  model.value = props.modelValue ?? [];
});

async function setTodayPeriod() {
  model.value = [startOfToday(), endOfToday()];
}

function setYesterdayPeriod() {
  model.value = [startOfYesterday(), endOfYesterday()];
}

function setLastWeekPeriod() {
  model.value = [startOfDay(subDays(new Date(), 7)), endOfToday()];
}

function onClear() {
  model.value = undefined;
}

function onCalendarHide() {
  const [start, end] = model.value ?? [];

  emit("select", {
    startDate: extractDateFromDatetime(start),
    endDate: extractDateFromDatetime(end),
  });
}
</script>

<template>
  <Calendar
    v-model="model"
    v-tooltip.top="tooltipText ? { value: tooltipText } : null"
    class="md:w-72"
    :max-date="new Date()"
    selection-mode="range"
    :manual-input="false"
    show-icon
    placeholder="Select a date range"
    :pt="{
      dropdownButton: {
        root: { class: 'p-button-outlined border-l-0 border-surface-300' },
      },
    }"
    @hide="onCalendarHide"
  >
    <template v-if="isClearable" #header>
      <div class="flex items-center lg:justify-between">
        <Button class="p-button-text" label="Clear" @click="onClear" />
      </div>
    </template>
    <template #footer>
      <div class="flex items-center lg:justify-between">
        <Button
          class="p-button-text"
          label="Last 7 days"
          @click="setLastWeekPeriod"
        />
        <Button
          class="p-button-text"
          label="Yesterday"
          @click="setYesterdayPeriod"
        />
        <Button class="p-button-text" label="Today" @click="setTodayPeriod" />
      </div>
    </template>
  </Calendar>
</template>
