export * from "./countries";
export * from "./languages";

import sortBy from "lodash.sortby";
import { FilterMatchMode } from "primevue/api";

import { SUPPORTED_COUNTRIES, getCountryInfo } from "@/constants/countries";
import {
  AdFormat,
  AdPlacement,
  AdStatus,
  AssetHtmlType,
  AssetRedirectionType,
  AssetStatus,
  AssetType,
  CampaignRoasType,
  CampaignStatus,
  CampaignType,
  DeviceType,
  Platform,
  type ReportFragment,
  Store,
} from "@/graphql";
import {
  CONTAINS_ALL,
  ROUNDED_EQUALS,
  ROUNDED_GREATER_THAN,
  ROUNDED_GREATER_THAN_OR_EQUALS,
  ROUNDED_LESS_THAN,
  ROUNDED_LESS_THAN_OR_EQUALS,
  ROUNDED_NOT_EQUALS,
} from "@/utils/filters/common";
import { prettifyAdFormat } from "@/utils/prettifiers/prettify-ad-format";
import { prettifyAdPlacement } from "@/utils/prettifiers/prettify-ad-placement";
import { prettifyAssetHtmlType } from "@/utils/prettifiers/prettify-asset-html-type";
import { prettifyAssetRedirectionType } from "@/utils/prettifiers/prettify-asset-redirection-type";
import { prettifyAssetStatus } from "@/utils/prettifiers/prettify-asset-status";
import { prettifyCampaignStatus } from "@/utils/prettifiers/prettify-campaign-status";
import { prettifyDeviceType } from "@/utils/prettifiers/prettify-device-type";

export const ALLOWED_TARGETING_COUNTRY_CODES = SUPPORTED_COUNTRIES.map(o => o.alpha3);

export const ALLOWED_TARGETING_COUNTRIES = sortBy(
  ALLOWED_TARGETING_COUNTRY_CODES.map(code => getCountryInfo(code)).filter(Boolean),
  country => country.name,
);

export type FormatOption = {
  value: AdFormat;
  label: string;
};

/** AdFormat.Video, AdFormat.Playable are temporary hidden */
export const AD_FORMAT_OPTIONS: FormatOption[] = [AdFormat.VideoPlayable, AdFormat.VideoIec].map(
  value => ({
    value,
    label: prettifyAdFormat(value),
  }),
);

export const NATIVE_AD_FORMAT_OPTIONS: FormatOption[] = [AdFormat.BeRealFeed].map(value => ({
  value,
  label: prettifyAdFormat(value),
}));

export const AD_PLACEMENT_OPTIONS: { value: AdPlacement; label: string }[] = Object.values(AdPlacement).map(
  value => ({
    value,
    label: prettifyAdPlacement(value),
  }),
);

export type DeviceTypeOption = {
  value: DeviceType;
  label: string;
};

export const DEVICE_TYPE_OPTIONS: DeviceTypeOption[] = Object.values(DeviceType).map(value => ({
  value,
  label: prettifyDeviceType(value),
}));

export const CAMPAIGN_STATUS_OPTIONS: { value: CampaignStatus; label: string }[] = Object.values(CampaignStatus).map(
  value => ({
    value,
    label: prettifyCampaignStatus(value),
  }),
);

export const CAMPAIGN_STATUS_ORDER: CampaignStatus[] = [
  CampaignStatus.Running,
  CampaignStatus.Stopped,
  CampaignStatus.Archived,
];

export type AssetRedirectionTypeOption = {
  value: AssetRedirectionType;
  label: string;
};

export const ASSET_REDIRECTION_TYPE_OPTIONS: AssetRedirectionTypeOption[] = Object.values(AssetRedirectionType).map(
  value => ({
    value,
    label: prettifyAssetRedirectionType(value),
  }),
);

export type AssetHtmlTypeOption = {
  value: AssetHtmlType;
  label: string;
};

export const ASSET_HTML_TYPE_OPTIONS: AssetHtmlTypeOption[] = Object.values(AssetHtmlType).map(value => ({
  value,
  label: prettifyAssetHtmlType(value),
}));

export type AssetStatusOption = {
  value: AssetStatus;
  icon: string;
  label: string;
};
export const ASSET_STATUS_OPTIONS: AssetStatusOption[] = [
  {
    value: AssetStatus.Pending,
    icon: "fa-thin fa-gear-complex text-gray-800",
    label: prettifyAssetStatus(AssetStatus.Pending),
  },
  {
    value: AssetStatus.Draft,
    icon: "fa fa-file-pen text-color-secondary",
    label: prettifyAssetStatus(AssetStatus.Draft),
  },
  {
    value: AssetStatus.Active,
    icon: "fa fa-wave-pulse text-green-600",
    label: prettifyAssetStatus(AssetStatus.Active),
  },
  {
    value: AssetStatus.Failed,
    icon: "fa fa-octagon-exclamation text-red-600",
    label: prettifyAssetStatus(AssetStatus.Failed),
  },
];

export type TableColumn<T, K = keyof T> = {
  id: K;
  label: string;
  description?: string;
  format?: Intl.NumberFormatOptions;
  minWidth?: string;
  displayedByDefault?: boolean;
};

export type REPORTING_COLUMN_KEYS = keyof Omit<ReportFragment, "__typename">;

export const REPORTING_COLUMNS: TableColumn<Omit<ReportFragment, "__typename">>[] = [
  {
    id: "nbOfImpressions",
    label: "# impressions",
    description: "Number of impressions",
    minWidth: "175px",
    displayedByDefault: true,
  },
  {
    id: "nbOfClicks",
    label: "# clicks",
    description: "Number of clicks",
    minWidth: "130px",
    displayedByDefault: true,
  },
  {
    id: "nbOfInstalls",
    label: "# installs",
    description: "Number of installations",
    minWidth: "140px",
    displayedByDefault: true,
  },
  {
    id: "clickthroughRate",
    label: "CTR",
    description: "Click Through Rate",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "100px",
    displayedByDefault: true,
  },
  {
    id: "conversionRate",
    label: "CVR",
    description: "Conversion Rate",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "100px",
    displayedByDefault: true,
  },
  {
    id: "installsPerMille",
    label: "IPM",
    description: "Installs Per Mille",
    minWidth: "100px",
    format: { maximumFractionDigits: 1 },
    displayedByDefault: true,
  },
  // { id: "estimatedSpend", label: "Estimated spend", format: { style: "currency", currency: "USD" }, minWidth: "200px" }, /** hidden for now */
  {
    id: "billableSpend",
    label: "Period spend",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "160px",
    displayedByDefault: true,
  },
  {
    id: "effectiveCostPerInstall",
    label: "eCPI",
    description: "Effective Cost Per Install",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "100px",
    displayedByDefault: true,
  },
  {
    id: "effectiveCostPerMille",
    label: "eCPM",
    description: "Effective Cost Per Mille",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "100px",
    displayedByDefault: true,
  },
  /**
   * ROAS and LTV reporting
   */
  /** DO */
  {
    id: "d0Roas",
    label: "D0 ROAS",
    description: "Revenues generated at D0/CPI - Both Ads & IAP revenues",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "120px",
    displayedByDefault: true,
  },
  {
    id: "lifeTimeValueAdvertisingD0",
    label: "LTV-0 (Ads)",
    description: "Revenues generated at D0 - Ads revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueInAppPurchaseD0",
    label: "LTV-0 (IAP)",
    description: "Revenues generated at D0/CPI - IAP revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueD0",
    label: "LTV-0",
    description: "Revenues generated at D0 - Both Ads & IAP revenues",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "110px",
  },
  /** D1 */
  {
    id: "d1Roas",
    label: "D1 ROAS",
    description: "Revenues generated at D1/CPI - Both Ads & IAP revenues",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "120px",
    displayedByDefault: true,
  },
  {
    id: "lifeTimeValueAdvertisingD1",
    label: "LTV-1 (Ads)",
    description: "Revenues generated from D0 to D1 - Ads revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueInAppPurchaseD1",
    label: "LTV-1 (IAP)",
    description: "Revenues generated from D0 to D1 - IAP revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueD1",
    label: "LTV-1",
    description: "Revenues generated from D0 to D1 - Both Ads & IAP revenues",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "110px",
  },
  /** D3 */
  {
    id: "d3Roas",
    label: "D3 ROAS",
    description: "Revenues generated at D3/CPI - Both Ads & IAP revenues",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "120px",
    displayedByDefault: true,
  },
  {
    id: "lifeTimeValueAdvertisingD3",
    label: "LTV-3 (Ads)",
    description: "Revenues generated from D0 to D3 - Ads revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueInAppPurchaseD3",
    label: "LTV-3 (IAP)",
    description: "Revenues generated from D0 to D3 - IAP revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueD3",
    label: "LTV-3",
    description: "Revenues generated from D0 to D3 - Both Ads & IAP revenues",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "110px",
  },
  /** D7 */
  {
    id: "d7Roas",
    label: "D7 ROAS",
    description: "Revenues generated at D7/CPI - Both Ads & IAP revenues",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "120px",
    displayedByDefault: true,
  },
  {
    id: "lifeTimeValueAdvertisingD7",
    label: "LTV-7 (Ads)",
    description: "Revenues generated from D0 to D7 - Ads revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueInAppPurchaseD7",
    label: "LTV-7 (IAP)",
    description: "Revenues generated from D0 to D7 - IAP revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueD7",
    label: "LTV-7",
    description: "Revenues generated from D0 to D7 - Both Ads & IAP revenues",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "110px",
  },
  /** D14 */
  {
    id: "d14Roas",
    label: "D14 ROAS",
    description: "Revenues generated at D14/CPI - Both Ads & IAP revenues",
    format: { style: "percent", maximumFractionDigits: 2 },
    minWidth: "120px",
    displayedByDefault: true,
  },
  {
    id: "lifeTimeValueAdvertisingD14",
    label: "LTV-14 (Ads)",
    description: "Revenues generated from D0 to D14 - Ads revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueInAppPurchaseD14",
    label: "LTV-14 (IAP)",
    description: "Revenues generated from D0 to D14 - IAP revenues only",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "155px",
  },
  {
    id: "lifeTimeValueD14",
    label: "LTV-14",
    description: "Revenues generated from D0 to D14 - Both Ads & IAP revenues",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "110px",
  },

  /** Revenue */
  {
    id: "inAppPurchaseRevenue",
    label: "IAP revenue",
    description: "In-app purchase revenue",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "150px",
  },
  {
    id: "advertisingRevenue",
    label: "Ads revenue",
    description: "Advertising revenue",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "150px",
  },
  {
    id: "totalRevenue",
    label: "Total revenue",
    description: "Total revenue",
    format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
    minWidth: "150px",
  },
];

export type AssetTypeFilterMenuItem = {
  label: string;
  icon: string;
  key: AssetType | null;
  tooltipText?: string;
  color?: string;
};

export type AssetSubTypeFilterMenuItem = {
  label: string;
  icon: string;
  key: AssetHtmlType | null;
  tooltipText?: string;
  color?: string;
};

export const ASSET_TYPE_FILTER_MENU_ITEMS: AssetTypeFilterMenuItem[] = [
  {
    key: null,
    label: "All",
    tooltipText: "All types",
    icon: "fa-regular fa-list",
  },
  {
    key: AssetType.Video,
    label: "Video",
    tooltipText: "Videos",
    icon: "fa-solid fa-clapperboard-play",
    color: "#36a2f8",
  },
  {
    key: AssetType.Html,
    label: "HTML",
    tooltipText: "HTML",
    icon: "fa-solid fa-file-code",
    color: "#10b981",
  },
];

export const ASSET_SUBTYPE_FILTER_MENU_ITEM: AssetSubTypeFilterMenuItem[] = [
  {
    key: AssetHtmlType.Iec,
    label: "IEC",
    tooltipText: "HTML",
    icon: "fa-solid fa-file-code",
    color: "#10b981",
  },
  {
    key: AssetHtmlType.Playable,
    label: "Playable",
    tooltipText: "Playable",
    icon: "fa-solid fa-file-code",
    color: "#10b981",
  },
  {
    key: AssetHtmlType.Static,
    label: "Static",
    tooltipText: "Static",
    icon: "fa-solid fa-file-code",
    color: "#10b981",
  },
];

/**
 * Store infos
 */
export const STORE_INFOS = {
  [Store.AppStore]: { id: Store.AppStore, label: "AppStore", icon: "pi pi-apple" },
  [Store.GooglePlay]: { id: Store.GooglePlay, label: "GooglePlay", icon: "pi pi-android" },
};

/**
 * Platform infos
 */
export const PLATFORM_INFOS = {
  [Platform.Ios]: { id: Platform.Ios, label: "iOS", icon: "pi pi-apple" },
  [Platform.Android]: { id: Platform.Android, label: "Android", icon: "pi pi-android" },
};

export const PLATFORM_OPTIONS = Object.values(PLATFORM_INFOS).map(({ id, label, icon }) => ({ value: id, label, icon }));

type CampaignSelectObjectType = {
  type: CampaignType;
  title: string;
  tagLabel: string;
  description: string;
  backgroundColor: string;
  color: string;
};

type CampaignFilterType = {
  value: CampaignType | CampaignRoasType;
  title: string;
  tagLabel: string;
  description: string;
  backgroundColor: string;
  color: string;
};

export const ROAS_TYPES: Record<string, CampaignFilterType> = {
  [CampaignRoasType.D0Roas]: {
    title: "D0 ROAS",
    value: CampaignRoasType.D0Roas,
    tagLabel: "D0",
    description: "1 day after acquisition",
    backgroundColor: "#e7d0fd",
    color: "#6b35a3",
  },
  [CampaignRoasType.D3Roas]: {
    title: "D3 ROAS",
    value: CampaignRoasType.D3Roas,
    tagLabel: "D3",
    description: "4 days after acquisition",
    backgroundColor: "#e7d0fd",
    color: "#6b35a3",
  },
  [CampaignRoasType.D7Roas]: {
    title: "D7 ROAS",
    tagLabel: "D7",
    value: CampaignRoasType.D7Roas,
    description: "8 days after acquisition",
    backgroundColor: "#e7d0fd",
    color: "#6b35a3",
  },
};

export const CAMPAIGN_FILTERS_OPTIONS: Record<string, CampaignFilterType> = {
  [CampaignType.Cpi]: {
    value: CampaignType.Cpi,
    title: "CPI Bid campaign",
    tagLabel: "CPI",
    description: "Cost per install",
    backgroundColor: "#d5adfc",
    color: "#52297d",
  },
  ...ROAS_TYPES,
};

export const CAMPAIGN_TYPE_OPTIONS: CampaignSelectObjectType[] = [
  {
    type: CampaignType.Cpi,
    title: "CPI Bid campaign",
    tagLabel: "CPI",
    description: "Cost per install campaign where you can set CPI bidding amounts.",
    backgroundColor: "#d5adfc",
    color: "#52297d",
  },
  {
    type: CampaignType.Roas,
    title: "ROAS campaign",
    tagLabel: "ROAS",
    description: "User acquisition campaign where you can set DX-ROAS goals to reach.",
    backgroundColor: "#e7d0fd",
    color: "#6b35a3",
  },
];

const yesterdayDate = new Date();
yesterdayDate.setDate(yesterdayDate.getDate() - 1);

export const YESTERDAY_DATE = yesterdayDate;

const AdStatusInfo: Record<AdStatus, { label: string; icon: string }> = {
  [AdStatus.Archived]: { label: "Archived", icon: "fa-regular fa-archive text-gray-600" },
  [AdStatus.Active]: { label: "Active", icon: "fa-regular fa-circle-play text-green-600" },
  [AdStatus.Stopped]: { label: "Stopped", icon: "fa-regular fa-circle-stop text-red-600" },
};

export const ADS_STATUS_OPTIONS = Object.entries(AdStatusInfo).map(([value, { label, icon }]) => ({
  value,
  label,
  icon,
}));

export type AdsManagementOptions = { value: boolean; label: string };

export const ADS_MANAGEMENT_OPTIONS: AdsManagementOptions[] = [
  { label: "Auto", value: true },
  { label: "Manual", value: false },
];

export const FILTERS_MODES_NUMERICAL = [
  { label: "=", name: "is equal", value: FilterMatchMode.EQUALS },
  { label: "≠", name: "is not equal", value: FilterMatchMode.NOT_EQUALS },
  { label: "<", name: "is less than", value: FilterMatchMode.LESS_THAN },
  { label: "≤", name: "is less or equal than", value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
  { label: ">", name: "is greater than", value: FilterMatchMode.GREATER_THAN },
  { label: "≥", name: "is greater or equal than", value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
];

export const FILTERS_MODES_NUMERICAL_FLOAT = [
  { label: "=", name: "is equal", value: ROUNDED_EQUALS },
  { label: "≠", name: "is not equal", value: ROUNDED_NOT_EQUALS },
  { label: "<", name: "is less than", value: ROUNDED_LESS_THAN },
  { label: "≤", name: "is less or equal than", value: ROUNDED_LESS_THAN_OR_EQUALS },
  { label: ">", name: "is greater than", value: ROUNDED_GREATER_THAN },
  { label: "≥", name: "is greater or equal than", value: ROUNDED_GREATER_THAN_OR_EQUALS },
];

export const FILTERS_MODES_ARRAY = [
  { label: "in", name: "in", value: FilterMatchMode.IN },
  { label: "contains all", name: "contains all", value: CONTAINS_ALL },
];

export const FILTERS_MODES_STRING = [
  { label: "contains", name: "contains", value: FilterMatchMode.CONTAINS },
  { label: "starts with", name: "starts with", value: FilterMatchMode.STARTS_WITH },
  { label: "ends with", name: "ends with", value: FilterMatchMode.ENDS_WITH },
];

export const FILTERS_MODES = [
  ...FILTERS_MODES_NUMERICAL,
  ...FILTERS_MODES_NUMERICAL_FLOAT,
  ...FILTERS_MODES_ARRAY,
  ...FILTERS_MODES_STRING,
];

export type FiltersModesKeys = (typeof FILTERS_MODES)[number]["value"];

export type FiltersModesOption = {
  label: string;
  name: string;
  value: FiltersModesKeys;
};

export const CAMPAIGN_STATUS_ICONS = {
  [CampaignStatus.Running]: "fa-regular fa-circle-play text-green-600",
  [CampaignStatus.Stopped]: "fa-regular fa-circle-stop text-red-600",
  [CampaignStatus.Archived]: "fa-regular fa-archive text-gray-500",
  [CampaignStatus.Pending]: "fa-regular fa-alarm-clock text-green-700",
  [CampaignStatus.Draft]: "fa-regular fa-file-pen text-gray-700",
};

export const IS_TEST_MODE_OPTIONS: AdsManagementOptions[] = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false },
];

export const BUDGET_INFO_TEXT = "The budget is calculated based on the current daily budget and can be false if it has been changed into the selected period.";

export const ROAS_LIMIT = 120;
