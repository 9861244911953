<script setup lang="ts">
import Button from "primevue/button";
import { computed } from "vue";
import { useRoute } from "vue-router";

import illustration from "./assets/illustration-maintenance.svg";

import useAuthentication from "@/composables/use-authentication";

const route = useRoute();

const { reload, permissions } = useAuthentication();

const nextLocation = computed(() => (route.query.next as string) || "");

const shouldReload = computed(() => (permissions.value || []).length === 0);

function redirectToNextLocation() {
  reload(nextLocation.value);
}

const redirectButtonLabel = computed(() => {
  if (shouldReload.value) {
    return "Reload";
  }

  if (nextLocation.value === "/") {
    return "OK";
  }

  return "Go back";
});
</script>

<template>
  <section class="maintenance-page">
    <section class="centered-wrapper">
      <div class="robots" />
      <img :src="illustration" alt="maintenance robots" class="illustration">
      <h1>Forbidden</h1>
      <p class="maintenance-message">
        <span>Sorry, you don't have access to this resource.</span>
        <span v-if="shouldReload">Ask the permission to your administrator and click the reload
          button.</span>
        <span v-else>Please contact your administrator to give you the permission.</span>
      </p>
      <Button :label="redirectButtonLabel" @click="redirectToNextLocation" />
    </section>
  </section>
</template>

<style lang="scss" scoped>
.maintenance-page {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 2rem;
  height: 100%;
  transform: translateX(-50%);
  text-align: center;

  display: grid;
  justify-items: center;

  .centered-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .illustration {
    width: 35rem;
    margin-bottom: 3rem;
  }

  h1 {
    width: 279px;
    height: 90px;
    font-size: 4rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;

    background-color: #d3ebfc; // TODO: use theme color
    color: #d3ebfc; // TODO: use theme color
    text-shadow: 0px 1px 3px rgb(255, 255, 255); // TODO: use theme color
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;

    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .maintenance-message {
    margin-bottom: 3rem;

    span {
      display: block;
    }
  }
}
</style>
