<script lang="ts" setup>
import { tv } from "tailwind-variants";
import { computed, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import CreateAssetsWatcherMedium from "./CreateAssetsWatcherMedium.vue";
import CreateAssetsWatcherSmall from "./CreateAssetsWatcherSmall.vue";

import { useCreateAssets } from "@/pages/assets/AssetsUpload/composables/use-create-assets";
import links from "@/router/links";

const size = ref<"small" | "medium">("medium");
const MAX_FILES = 3;

const { files, isWatcherVisible } = useCreateAssets();

const pendingFiles = computed(() =>
  files.value.filter(file => file.isLoading),
);

const router = useRouter();

/** Prevent unwanted tab close when files are still uploading */
watch(
  pendingFiles,
  (pendingFilesValue) => {
    if (pendingFilesValue.length === 0) {
      window.onbeforeunload = null;
    }
    else {
      /** Message not displayed > https://stackoverflow.com/a/50132210 */
      window.onbeforeunload = () => {
        router.push({ name: links.assets.upload });
        return "You have files uploading, are you sure you want to leave this page?";
      };
    }
  },
  { deep: true },
);

onUnmounted(() => {
  window.onbeforeunload = null;
});

const remainingFilesLength = computed(() =>
  files.value.length > MAX_FILES ? files.value.length - MAX_FILES : 0,
);

const filesList = computed(() => {
  if (pendingFiles.value.length > MAX_FILES) {
    return pendingFiles.value.slice(0, MAX_FILES);
  }

  return pendingFiles.value;
});

const globalProgress = computed(() => {
  const totalProgress = files.value.reduce(
    (acc, file) => acc + file.uploadProgression,
    0,
  );

  return totalProgress / files.value.length;
});

function onToggleSize() {
  size.value = size.value === "medium" ? "small" : "medium";
}

const uploadsWatcherClass = tv({
  base: "uploads-watcher white-box fixed bottom-6 right-8 z-[999] flex h-auto w-80 flex-col items-center justify-center rounded-lg text-sm",
  variants: {
    size: {
      small: "size-14 rounded-full p-0",
      medium: "p-6",
    },
  },
});
</script>

<template>
  <div
    v-if="pendingFiles.length > 0 && isWatcherVisible"
    :class="[uploadsWatcherClass({ size }), { 'pb-3': remainingFilesLength && size === 'medium' }]"
  >
    <CreateAssetsWatcherSmall
      v-if="size === 'small'"
      :progress="globalProgress"
      :files-length="files.length"
      @toggle="onToggleSize"
    />

    <CreateAssetsWatcherMedium
      v-else
      :files="filesList"
      :remaining-files-length="remainingFilesLength"
      @toggle="onToggleSize"
    />
  </div>
</template>

<style scoped lang="scss">
.uploads-watcher {
  transition: width 0.2s ease-in-out, height 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}
</style>
