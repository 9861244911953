import { type Ref, type WatchStopHandle, ref, watchEffect } from "vue";

export type ConfirmMessage = {
  header?: string | undefined;
  message: string;
  icon?: string | undefined;
  accept?: () => void;
  reject?: () => void;
  acceptLabel?: string | undefined;
  rejectLabel?: string | undefined;
  acceptIcon?: string | undefined;
  rejectIcon?: string | undefined;
  acceptClass?: string | undefined;
  rejectClass?: string | undefined;
  defaultFocus?: string | undefined;
};

const messenger = ref<ConfirmMessage | null>(null);
const answer = ref<boolean | null>(null);

type UseConfirmDialog = {
  messenger: Ref<ConfirmMessage | null>;
  answer: Ref<boolean | null>;
  confirm: (message: ConfirmMessage) => Promise<boolean>;
  alert: (message: ConfirmMessage) => void;
  alertWarning: (message: string) => void;
  alertDanger: (message: string) => void;
  confirmDanger: (message: string) => Promise<boolean>;
  confirmWarning: (message: string) => Promise<boolean>;
  confirmInfo: (message: string) => Promise<boolean>;
};

export default function useConfirmDialog(): UseConfirmDialog {
  const alert = (message: ConfirmMessage): void => {
    messenger.value = { ...message };
  };

  const alertWarning = (message: string): void => {
    alert({
      message,
      icon: "pi pi-exclamation-triangle",
      acceptClass: "p-button-warning",
      acceptLabel: "Back",
      rejectLabel: " ",
    });
  };

  const alertDanger = (message: string): void => {
    alert({
      message,
      header: "Caution",
      icon: "pi pi-exclamation-triangle",
      acceptClass: "p-button-danger",
      acceptLabel: "Back",
      rejectLabel: " ",
    });
  };

  const confirm = async (message: ConfirmMessage): Promise<boolean> => {
    const accept = (): void => {
      answer.value = true;
    };
    const reject = (): void => {
      answer.value = false;
    };

    messenger.value = { ...message, accept, reject };

    return new Promise((resolve) => {
      const unwatch: WatchStopHandle = watchEffect(() => {
        if (answer.value != null) {
          resolve(answer.value);
          unwatch();
        }
        answer.value = null;
      });
    });
  };

  const confirmDanger = (message: string): Promise<boolean> =>
    confirm({
      message,
      header: "Caution",
      icon: "pi pi-exclamation-triangle",
      acceptClass: "p-button-danger",
      acceptLabel: "Yes, I'm sure",
    });

  const confirmWarning = (message: string): Promise<boolean> =>
    confirm({
      message,
      header: "Warning",
      icon: "pi pi-exclamation-triangle",
      acceptClass: "p-button-warning",
    });

  const confirmInfo = (message: string): Promise<boolean> =>
    confirm({
      message,
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClass: "p-button-info",
    });

  return {
    messenger,
    answer,
    confirm,
    alert,
    alertWarning,
    alertDanger,
    confirmDanger,
    confirmWarning,
    confirmInfo,
  };
}
