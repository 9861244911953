import omit from "lodash.omit";
import { FilterMatchMode } from "primevue/api";
import { z } from "zod";

import type { CampaignsListRow } from "./types";

import type { FilterConfig } from "@/components/business/ListFilters/types";
import usePermissions from "@/composables/use-permissions";
import { DEFAULT_REPORTING_FILTERS_CONFIG, useStateFilters } from "@/composables/use-state-filters";
import { FILTERS_MODES_NUMERICAL_FLOAT } from "@/constants";
import { AdPlacement, CampaignRoasType, CampaignStatus, CampaignType, DeviceType, Store } from "@/graphql";
import { booleanField } from "@/utils/filters/boolean-filters";
import { CONTAINS_ALL, filterArray } from "@/utils/filters/common";
import { numericFloatDefaultValue, numericalFieldFloat } from "@/utils/filters/numerical-filters";

const campaignsListFiltersConfig = {
  "global": {
    id: "global",
    title: "Search",
    initialValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    schema: {
      field: z.object({ value: z.string().nullable(), matchMode: z.literal(FilterMatchMode.CONTAINS) }),
      multi: false,
      encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["CONTAINS"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.CONTAINS }),
    },
    inputComponent: "SearchFilterInput",
    tagValueComponent: null,
    isAlwaysDisplayed: true,
  },
  "status": {
    id: "status",
    title: "Status",
    initialValue: { value: [], matchMode: FilterMatchMode.IN },
    schema: {
      field: z.object({ value: z.array(z.nativeEnum(CampaignStatus)), matchMode: z.literal(FilterMatchMode.IN) }),
      multi: true,
      encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.IN }),
    },
    inputComponent: "StatusFilterInput",
    tagValueComponent: "StatusFilterTag",
    isAlwaysDisplayed: false,
  },
  "countries": {
    id: "countries",
    title: "Countries",
    initialValue: { value: [], matchMode: CONTAINS_ALL },
    schema: {
      field: z.object({ value: z.array(z.string()), matchMode: z.literal(CONTAINS_ALL) }),
      multi: true,
      encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: CONTAINS_ALL }),
    },
    inputComponent: "CountriesFilterInput",
    tagValueComponent: "CountryFilterTag",
    isAlwaysDisplayed: false,
  },
  "store": {
    id: "store",
    title: "Store",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: {
      field: z.object({ value: z.nativeEnum(Store).nullable(), matchMode: z.literal(FilterMatchMode.EQUALS) }),
      multi: false,
      encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
    },
    inputComponent: "StoreFilterInput",
    tagValueComponent: "StoreFilterTag",
    isAlwaysDisplayed: false,
  },
  "app.id": {
    id: "app.id",
    title: "Apps",
    initialValue: { value: [], matchMode: FilterMatchMode.IN },
    schema: {
      field: z.object({
        value: z.string().or(z.array(z.string())).nullable(),
        matchMode: z.literal(FilterMatchMode.IN),
      }),
      multi: true,
      encode: (obj: { value: string[] | null; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.IN }),
    },
    inputComponent: "AppFilterInput",
    tagValueComponent: "AppFilterTag",
    isAlwaysDisplayed: false,
  },
  "deviceType": {
    id: "deviceType",
    title: "Device type",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: {
      field: z.object({ value: z.nativeEnum(DeviceType).nullable(), matchMode: z.literal(FilterMatchMode.EQUALS) }),
      multi: false,
      encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
    },
    inputComponent: "DeviceTypeFilterInput",
    tagValueComponent: "DeviceTypeFilterTag",
    isAlwaysDisplayed: false,
  },
  "hasAutomaticAdsManagement": {
    id: "hasAutomaticAdsManagement",
    title: "Ads Management",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: booleanField,
    inputComponent: "AdsManagementFilterInput",
    tagValueComponent: "AdsManagementFilterTag",
    isAlwaysDisplayed: false,
  },

  "campaignType": {
    id: "campaignType",
    title: "Type",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: {
      field: z.object({
        value: z.nativeEnum(CampaignType).or(z.nativeEnum(CampaignRoasType)).nullable(),
        matchMode: z.literal(FilterMatchMode.EQUALS),
      }),
      multi: false,
      encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
    },
    inputComponent: "CampaignTypeFilterInput",
    tagValueComponent: "CampaignTypeTag",
    isAlwaysDisplayed: false,
  },

  "isTestMode": {
    id: "isTestMode",
    title: "Test mode",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: booleanField,
    inputComponent: "IsTestModeFilterInput",
    tagValueComponent: "IsTestModeFilterTag",
    isAlwaysDisplayed: false,
    visibility: () => {
      const { hasCampaignTestModeWritePermission } = usePermissions();
      return hasCampaignTestModeWritePermission.value;
    },
  },

  "todaySpend": {
    id: "todaySpend",
    title: "Today's spend",
    initialValue: numericFloatDefaultValue,
    schema: numericalFieldFloat,
    inputComponent: "NumericalFilterInput",
    tagValueComponent: "NumericalFilterTag",
    format: { style: "currency", currency: "USD" },
    matchModes: FILTERS_MODES_NUMERICAL_FLOAT,
  },

  "periodSpend": {
    id: "periodSpend",
    title: "Period spend",
    initialValue: numericFloatDefaultValue,
    schema: numericalFieldFloat,
    inputComponent: "NumericalFilterInput",
    tagValueComponent: "NumericalFilterTag",
    format: { style: "currency", currency: "USD" },
    matchModes: FILTERS_MODES_NUMERICAL_FLOAT,
  },

  "totalDailyBudget": {
    id: "totalDailyBudget",
    title: "Daily budget",
    initialValue: numericFloatDefaultValue,
    schema: numericalFieldFloat,
    inputComponent: "NumericalFilterInput",
    tagValueComponent: "NumericalFilterTag",
    format: { style: "currency", currency: "USD" },
    matchModes: FILTERS_MODES_NUMERICAL_FLOAT,
  },

  "adPlacement": {
    id: "adPlacement",
    title: "Placement",
    initialValue: { value: [], matchMode: FilterMatchMode.IN },
    schema: {
      field: z.object({ value: z.array(z.nativeEnum(AdPlacement)), matchMode: z.literal(FilterMatchMode.IN) }),
      multi: true,
      encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.IN }),
    },
    inputComponent: "AdPlacementFilterInput",
    tagValueComponent: "AdPlacementFilterTag",
    isAlwaysDisplayed: false,
  },

  ...omit(DEFAULT_REPORTING_FILTERS_CONFIG, "billableSpend"),
} satisfies Record<string, FilterConfig>;

const campaignsListGlobalFiltersKeys = ["name", "appRawId", "app.name"];

/**
 * Composable to manage the filters of the campaigns list.
 */
export function useFilters() {
  const { filters, clear, initialValues } = useStateFilters(campaignsListFiltersConfig);

  function filterRows(rows: CampaignsListRow[]) {
    return filterArray(filters.value, rows, campaignsListGlobalFiltersKeys);
  }

  return {
    filters,
    initialValues,
    config: campaignsListFiltersConfig,
    clear,
    filterRows,
  };
}
