import { type Ref, computed, ref } from "vue";

import blankReport from "../campaign-details/blank-report";

import type { ReportingDateRange } from "@/composables/use-reporting-daterange";
import { type CampaignsListPageCampaignFragment as CampaignFragment, CampaignStatus, type CampaignsListPageReportsQueryVariables, type ReportFragment } from "@/graphql";
import { useCampaignsListPageQuery } from "@/pages/campaigns/CampaignsList/queries/campaigns.query.graphql";
import { useCampaignsListPageReportsQuery } from "@/pages/campaigns/CampaignsList/queries/reporting.query.graphql";
import type { CampaignsListRow } from "@/pages/campaigns/CampaignsList/types";
import { extractDateFromDatetime } from "@/utils/common";

export function useData({ reportingDateRange, showArchived }: { reportingDateRange: ReportingDateRange; showArchived: Ref<boolean> }) {
  /**
   * Fill this array with the fetched campaigns ids
   * it will be passed to the report query
   */
  const fetchedCampaignsIds = ref<string[]>([]);

  const campaignsListQueryVariables = computed(() => ({
    showTestMode: true,
    sorts: "-creationDate",
    status: [
      /* CampaignStatus.Pending, // TODO */
      CampaignStatus.Running,
      CampaignStatus.Stopped,
      CampaignStatus.Draft,
      ...(showArchived.value ? [CampaignStatus.Archived] : []),
    ],
  }));

  const campaigns = ref<CampaignFragment[]>([]);
  const reports = ref<Record<string, ReportFragment>>({});
  const todaySpentBudgetByCampaignId = ref<Record<string, number>>({});

  const {
    isPending: isCampaignsLoading,
    onResult: onResultCampaigns,
    error: campaignsError,
  } = useCampaignsListPageQuery(campaignsListQueryVariables);

  onResultCampaigns((data) => {
    campaigns.value = data.campaigns.edges.map(o => o.node) ?? [];
    fetchedCampaignsIds.value = campaigns.value.map(o => o.id);
  });

  /**
   * Reports
   */

  const isReportingQueryEnabled = computed(
    () => fetchedCampaignsIds.value.length > 0,
  );

  const reportQueryVariables = computed<CampaignsListPageReportsQueryVariables>(() => ({
    campaignIds: fetchedCampaignsIds.value,
    startDate: extractDateFromDatetime(reportingDateRange.value.start),
    endDate: extractDateFromDatetime(reportingDateRange.value.end),
    today: extractDateFromDatetime(new Date()),
  }));

  const {
    isPending: isReportsLoading,
    onResult: onResultReports,
    isError: isReportsError,
  } = useCampaignsListPageReportsQuery(reportQueryVariables, {
    enabled: isReportingQueryEnabled,
  });

  onResultReports((data) => {
    reports.value = Object.fromEntries(data.campaignReports.map(({ campaignId, report }) => [campaignId, report]));

    todaySpentBudgetByCampaignId.value = Object.fromEntries(data.todaySpentBudgetByCampaignIds.map(
      ({ campaignId, report }) => [campaignId, report.billableSpend],
    ));
  });

  const rows = computed<CampaignsListRow[]>(() =>
    campaigns.value.map((campaign) => {
      const report = reports.value[campaign.id] ?? blankReport;
      const todaySpend = todaySpentBudgetByCampaignId.value[campaign.id] ?? 0;

      const nbReportDays = Math.ceil((reportingDateRange.value.end.getTime() - reportingDateRange.value.start.getTime()) / (1000 * 60 * 60 * 24)) + 1;

      let periodSpendProgression = 0;

      if (campaign.totalDailyBudget && report.billableSpend) {
        periodSpendProgression = (report.billableSpend / (campaign.totalDailyBudget * nbReportDays))
        * 100;
      }

      let todaySpendProgression = 0;

      if (campaign.totalDailyBudget && todaySpend) {
        todaySpendProgression = (todaySpend / campaign.totalDailyBudget) * 100;
      }

      return {
        ...campaign,
        appRawId: campaign.app ? atob(campaign.app.id ?? "").split(":")[1] : null,
        ...report,
        todaySpend,
        todaySpendProgression,
        hasAutomaticAdsManagement:
          campaign.adsManagement.hasAutomaticAdsManagement,
        campaignType: campaign.roasType ?? campaign.type,
        periodDailyBudget: campaign.totalDailyBudget * nbReportDays,
        periodSpend: report.billableSpend ?? 0,
        periodSpendProgression,
        adPlacement: campaign.adsManagement.adsPlacement,
      };
    }),
  );

  return {
    isCampaignsLoading,
    isReportsLoading,
    isReportsError,
    campaignsError,
    rows,
  };
}
