import type { ColumnFilterModelType } from "primevue/column";

import AdPlacementFilterInput from "./components/filter-inputs/AdPlacementFilterInput/AdPlacementFilterInput.vue";
import AdSetFilterInput from "./components/filter-inputs/AdSetFilterInput/AdSetFilterInput.vue";
import AdsManagementFilterInput from "./components/filter-inputs/AdsManagementFilterInput/AdsManagementFilterInput.vue";
import AdStatusFilterInput from "./components/filter-inputs/AdStatusFilterInput/AdStatusFilterInput.vue";
import AppFilterInput from "./components/filter-inputs/AppFilterInput/AppFilterInput.vue";
import AssetStatusFilterInput from "./components/filter-inputs/AssetStatusFilterInput/AssetStatusFilterInput.vue";
import AssetTypeFilterInput from "./components/filter-inputs/AssetTypeFilterInput/AssetTypeFilterInput.vue";
import CampaignTypeFilterInput from "./components/filter-inputs/CampaignTypeFilterInput/CampaignTypeFilterInput.vue";
import CountriesFilterInput from "./components/filter-inputs/CountriesFilterInput/CountriesFilterInput.vue";
import DeviceTypeFilterInput from "./components/filter-inputs/DeviceTypeFilterInput/DeviceTypeFilterInput.vue";
import FormatFilterInput from "./components/filter-inputs/FormatFilterInput/FormatFilterInput.vue";
import IsTestModeFilterInput from "./components/filter-inputs/IsTestModeFilterInput/IsTestModeFilterInput.vue";
import LanguageFilterInput from "./components/filter-inputs/LanguageFilterInput/LanguageFilterInput.vue";
import NumericalFilterInput from "./components/filter-inputs/NumericalFilterInput/NumericalFilterInput.vue";
import PlatformsFilterInput from "./components/filter-inputs/PlatformsFilterInput/PlatformsFilterInput.vue";
import SearchFilterInput from "./components/filter-inputs/SearchFilterInput/SearchFilterInput.vue";
import StatusFilterInput from "./components/filter-inputs/StatusFilterInput/StatusFilterInput.vue";
import StoreFilterInput from "./components/filter-inputs/StoreFilterInput/StoreFilterInput.vue";
import AdPlacementFilterTag from "./components/filter-tags/AdPlacementFilterTag/AdPlacementFilterTag.vue";
import AdSetFilterTag from "./components/filter-tags/AdSetFilterTag/AdSetFilterTag.vue";
import AdsManagementFilterTag from "./components/filter-tags/AdsManagementFilterTag/AdsManagementFilterTag.vue";
import AdStatusFilterTag from "./components/filter-tags/AdStatusFilterTag/AdStatusFilterTag.vue";
import AppFilterTag from "./components/filter-tags/AppFilterTag/AppFilterTag.vue";
import AssetStatusFilterTag from "./components/filter-tags/AssetStatusFilterTag/AssetStatusFilterTag.vue";
import AssetTypeFilterTag from "./components/filter-tags/AssetTypeFilterTag/AssetTypeFilterTag.vue";
import CampaignTypeTag from "./components/filter-tags/CampaignTypeTag/CampaignTypeTag.vue";
import CountryFilterTag from "./components/filter-tags/CountryFilterTag/CountryFilterTag.vue";
import DeviceTypeFilterTag from "./components/filter-tags/DeviceTypeFilterTag/DeviceTypeFilterTag.vue";
import FormatFilterTag from "./components/filter-tags/FormatFilterTag/FormatFilterTag.vue";
import IsTestModeFilterTag from "./components/filter-tags/IsTestModeFilterTag/IsTestModeFilterTag.vue";
import LanguageTag from "./components/filter-tags/LanguageTag/LanguageTag.vue";
import NumericalFilterTag from "./components/filter-tags/NumericalFilterTag/NumericalFilterTag.vue";
import PlatformsFilterTag from "./components/filter-tags/PlatformsFilterTag/PlatformsFilterTag.vue";
import StatusFilterTag from "./components/filter-tags/StatusFilterTag/StatusFilterTag.vue";
import StoreFilterTag from "./components/filter-tags/StoreFilterTag/StoreFilterTag.vue";

import type { SchemaField } from "@/composables/use-url-state";
import type { FiltersModesOption } from "@/constants";

export const inputComponentsMapping = {
  AdSetFilterInput,
  AdsManagementFilterInput,
  AdStatusFilterInput,
  FormatFilterInput,
  SearchFilterInput,
  LanguageFilterInput,
  StatusFilterInput,
  CountriesFilterInput,
  StoreFilterInput,
  AppFilterInput,
  CampaignTypeFilterInput,
  DeviceTypeFilterInput,
  NumericalFilterInput,
  IsTestModeFilterInput,
  PlatformsFilterInput,
  AssetTypeFilterInput,
  AssetStatusFilterInput,
  AdPlacementFilterInput,
} as const;

export const tagValueComponentsMapping = {
  AdsManagementFilterTag,
  AdStatusFilterTag,
  StatusFilterTag,
  CountryFilterTag,
  StoreFilterTag,
  AppFilterTag,
  CampaignTypeTag,
  DeviceTypeFilterTag,
  FormatFilterTag,
  LanguageTag,
  NumericalFilterTag,
  AdSetFilterTag,
  IsTestModeFilterTag,
  PlatformsFilterTag,
  AssetTypeFilterTag,
  AssetStatusFilterTag,
  AdPlacementFilterTag,
} as const;

export type FilterConfig = {
  id: string;
  title: string;
  initialValue: ColumnFilterModelType;
  schema: SchemaField<ColumnFilterModelType>;
  inputComponent: keyof typeof inputComponentsMapping;
  tagValueComponent: keyof typeof tagValueComponentsMapping | null;
  isAlwaysDisplayed?: boolean;
  /* Group id also used as a group name (for now) */
  group?: string;
  matchModes?: FiltersModesOption[];
  format?: Intl.NumberFormatOptions & {
    /*
     * Specifies the format for representing percentages.
     * If set to 'decimal' (default), percentages are represented as a fraction of 1 (e.g., 0.01 for 1%, 0.1 for 10%).
     * If set to 'absolute', percentages are represented as their actual value (e.g., 10 for 10%, 25.3 for 25.3%).
     * This is used by the NumericalFilterInput component to determine how to display the percentage value.
     */
    percentMode?: "absolute" | "decimal";
  };
  /*
   * Wether the filter should be displayed to the user. Usefull for handling permissions
   */
  visibility?: () => boolean;
};
