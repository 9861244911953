import { BRANDING_CAMPAIGNS_LIST_PAGE_ID } from "./constants";
import type { BrandingCampaignsListRow } from "./types";

import usePersistentColumns from "@/composables/use-persistent-columns";
import type { TableColumn } from "@/constants";

/**
 * Columns order customization
 */
export const customizableColumns: TableColumn<BrandingCampaignsListRow>[] = [
  { id: "deviceType", label: "Device Type" },
  { id: "platforms", label: "Platforms" },
  { id: "countries", label: "Countries", displayedByDefault: true },
  { id: "status", label: "Status", displayedByDefault: true },
];

export function useColumns() {
  const { selectedColumns } = usePersistentColumns(
    `${BRANDING_CAMPAIGNS_LIST_PAGE_ID}-columns`,
    customizableColumns,
  );

  return {
    customizableColumns,
    selectedColumns,
  };
}
