<script setup lang="ts">
import Button from "primevue/button";
import { useRoute } from "vue-router";

import Logo from "@/assets/adn-logo-blue.svg";
import useAuthentication from "@/composables/use-authentication";

const route = useRoute();

const { login } = useAuthentication();

function onLogin() {
  login(route.query?.next?.toString());
}

/**
 * If `auth0=true` is passed as a query, then redirect directly to auth0.
 * This is used by auth0 to allow user invitations to an organization for instance.
 */
if (route.query.auth0 === "true") {
  const invitation = route.query?.invitation as string | undefined;
  const organization = route.query?.organization as string | undefined;
  login(route.query?.next?.toString(), {
    invitation,
    organization,
  });
}
</script>

<template>
  <div class="login-page relative flex h-screen w-screen items-center justify-center overflow-hidden px-6 lg:pr-12">
    <div class="login-container z-10 flex max-w-lg flex-col items-center justify-center border border-solid border-white bg-white/80 p-8 pt-20 text-center blur transition-shadow duration-1000">
      <div class="login-page-logo mb-4 w-3/12">
        <img :src="Logo" alt="Logo">
      </div>
      <h1 class="login-page-title m-0 text-6xl text-slate-800">
        Ad Network
      </h1>

      <p class="text-color-secondary mt-1">
        Manage your ad campaigns and track your performance with our easy-to-use dashboard.
      </p>

      <Button label="Login" class="login-page-login-button mt-12 w-full" @click="onLogin" />
    </div>

    <div class="absolute inset-0">
      <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice" class="size-full">
        <defs>
          <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5">
            <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite" />
            <stop offset="0%" stop-color="rgba(192, 155, 255, 1)" />
            <stop offset="100%" stop-color="rgba(192, 155, 255, 0)" />
          </radialGradient>
          <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5">
            <animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite" />
            <stop offset="0%" stop-color="rgba(244, 139, 243, 1)" />
            <stop offset="100%" stop-color="rgba(244, 139, 243, 0)" />
          </radialGradient>
          <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
            <animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite" />
            <stop offset="0%" stop-color="rgba(155, 193, 255, 1)" />
            <stop offset="100%" stop-color="rgba(155, 193, 255, 0)" />
          </radialGradient>
        </defs>
        <rect
          x="13.744%"
          y="1.18473%"
          width="100%"
          height="100%"
          fill="url(#Gradient1)"
          transform="rotate(334.41 50 50)"
        >
          <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
          <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="15s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="-2.17916%"
          y="35.4267%"
          width="100%"
          height="100%"
          fill="url(#Gradient2)"
          transform="rotate(255.072 50 50)"
        >
          <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite" />
          <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite" />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="20s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="9.00483%"
          y="14.5733%"
          width="100%"
          height="100%"
          fill="url(#Gradient3)"
          transform="rotate(139.903 50 50)"
        >
          <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite" />
          <animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite" />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="360 50 50"
            to="0 50 50"
            dur="25s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.login-container {
  border-radius: var(--border-radius);

  &:hover {
    box-shadow: -5px -5px 30px 5px rgba(255, 0, 0, 0.1), 5px 5px 30px 5px rgba($color: blue, $alpha: 0.1);
  }
}
</style>
