<script setup lang="ts">
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { watch } from "vue";
import { RouterView } from "vue-router";

import VdOnlineChecker from "./components/structures/VdOnlineChecker/VdOnlineChecker.vue";

import useConfirmMessage from "@/composables/use-confirm-messages";
import useNotifications from "@/composables/use-notifications";

const toast = useToast();
const confirm = useConfirm();

const { messenger: toastMessenger } = useNotifications();
const { messenger: confirmMessenger } = useConfirmMessage();

watch(toastMessenger, (notification) => {
  if (notification != null) {
    toast.add({
      severity: notification.severity,
      summary: notification.title,
      detail: notification.detail,
      life: notification.life,
      closable: notification.closable,
    });
  }
});

watch(confirmMessenger, (confirmMessage) => {
  if (confirmMessage != null) {
    confirm.require({
      message: confirmMessage.message,
      header: confirmMessage.header,
      icon: confirmMessage.icon,
      accept: confirmMessage.accept,
      reject: confirmMessage.reject,
      acceptLabel: confirmMessage.acceptLabel,
      rejectLabel: confirmMessage.rejectLabel,
      acceptIcon: confirmMessage.acceptIcon,
      rejectIcon: confirmMessage.rejectIcon,
      acceptClass: confirmMessage.acceptClass,
      rejectClass: confirmMessage.rejectClass,
      defaultFocus: confirmMessage.defaultFocus,
    });
  }
});
</script>

<template>
  <div class="adn-application flex min-h-screen">
    <Toast position="top-right" />
    <ConfirmDialog />
    <RouterView />
    <VdOnlineChecker />
  </div>
</template>
