import { type ComputedRef, type MaybeRef, unref } from "vue";

import {
  type BrandingCampaignsListPageQueryVariables,
  buildQuery,
  buildQueryKey,
  graphql,
  runGraphqlQuery,
} from "@/graphql";

const query = graphql(/* GraphQL */ `
  query BrandingCampaignsListPage($showTestMode: Boolean, $status: [CampaignStatus!]!, $sorts: String) {
    brandingCampaigns(first: 10000, showTestMode: $showTestMode, status: $status, sorts: $sorts) {
      edges {
        node {
          ...BrandingCampaignsListPageCampaign
        }
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment BrandingCampaignsListPageCampaign on BrandingCampaign {
    id
    name
    status
    platforms
    deviceType
    startDate
    endDate
    dailyBudget
    cpm
    nbMaxAdsFrequency
    nbDaysAdsFrequency
    dailyBudget
    creationDate
    updateDate
    isTestMode
    countries
  }
`);

export function getQueryOptions(variables: MaybeRef<BrandingCampaignsListPageQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    queryFn() {
      return runGraphqlQuery(query, unref(variables));
    },
  };
}

export function useBrandingCampaignsListPageQuery(variables: ComputedRef<BrandingCampaignsListPageQueryVariables>) {
  return buildQuery(getQueryOptions(variables));
}
