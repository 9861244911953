<script setup lang="ts">
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import ProgressBar from "primevue/progressbar";
import Skeleton from "primevue/skeleton";
import { computed, ref } from "vue";

import type { CampaignsListRow } from "../../types";
import CampaignDetailsMenu from "../CampaignDetailsMenu/CampaignDetailsMenu.vue";

import AppChip from "@/components/business/AppChip/AppChip.vue";
import CampaignStatusChip from "@/components/business/CampaignStatusChip/CampaignStatusChip.vue";
import CampaignTypeTag from "@/components/business/CampaignTypeTag/CampaignTypeTag.vue";
import IsTestModeTag from "@/components/business/IsTestModeTag/IsTestModeTag.vue";
import ReportingTableColumn from "@/components/business/ReportingTableColumn/ReportingTableColumn.vue";
import VdCountryChip from "@/components/structures/VdCountryChip/VdCountryChip.vue";
import VdEmpty from "@/components/structures/VdEmpty/VdEmpty.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import VdTableSkeleton from "@/components/structures/VdTableSkeleton/VdTableSkeleton.vue";
import { useHorizontalScrollBoundaries } from "@/composables/use-horizontal-scroll-boundaries";
import usePermissions from "@/composables/use-permissions";
import useResponsive from "@/composables/use-responsive";
import type { TableColumn } from "@/constants";
import { BUDGET_INFO_TEXT } from "@/constants";
import links from "@/router/links";
import type { DataTableColumnBodySlot } from "@/types/helpers";
import { prettifyAdPlacement } from "@/utils/prettifiers/prettify-ad-placement";
import { prettifyCurrency } from "@/utils/prettifiers/prettify-currency";
import { prettifyDeviceType } from "@/utils/prettifiers/prettify-device-type";
import { campaignStatusSort } from "@/utils/sort-campaign-status";

const props = defineProps<{
  rows: CampaignsListRow[];
  filteredRows: CampaignsListRow[];
  selectedColumns: TableColumn<CampaignsListRow>[];
  isLoading: boolean;
  isReportsLoading: boolean;
  isReportsError: boolean;
}>();

const currentColumns = computed<TableColumn<CampaignsListRow>[]>(() => [
  { id: "name", label: "Name" },
  ...props.selectedColumns,
]);

const table = ref();
const { hasScrollReachedLeft, hasScrollReachedRight } = useHorizontalScrollBoundaries(table);

const { isMobileScreen } = useResponsive();

const { hasCampaignTestModeWritePermission } = usePermissions();

type ColumnData = DataTableColumnBodySlot<CampaignsListRow>;
</script>

<template>
  <div class="campaigns-list-table-component">
    <VdTableSkeleton v-if="isLoading" :columns="currentColumns" />

    <slot v-else-if="rows.length === 0" name="empty" />

    <VdEmpty v-else-if="filteredRows.length === 0" title="no data">
      <p class="text-color-secondary m-0">
        No campaign matching your filters.
      </p>
    </VdEmpty>

    <DataTable
      v-else
      ref="table"
      class="campaigns-table p-datatable-sm relative z-0"
      row-hover
      data-key="id"
      :value="filteredRows"
      striped-rows
      removable-sort
      scrollable
      paginator
      :rows="10"
      :rows-per-page-options="[5, 10, 20, 50]"
      sort-mode="multiple"
      :pt="{ bodyRow: { 'data-test': 'campaign-row' } }"
    >
      <template #header>
        <slot name="header" />
      </template>

      <Column
        field="name"
        header="Name"
        sortable
        :style="{ 'min-width': '250px' }"
        :frozen="!isMobileScreen"
        class="static-column z-30"
        :class="[{ 'column-left-raised': !hasScrollReachedLeft }]"
      >
        <template #body="{ data }: ColumnData">
          <VdRouterLink
            :to="{
              name: links.campaigns.details.countries,
              params: { campaignId: data.id },
            }"
            data-test="campaigns-list-campaign-name"
          >
            <div class="flex items-center">
              <span>{{ data.name }}</span>
            </div>
          </VdRouterLink>
        </template>
      </Column>

      <Column
        v-if="hasCampaignTestModeWritePermission"
        field="isTestMode"
        header="Test"
        sortable
        :style="{ 'min-width': '60px' }"
        class="static-column text-center"
      >
        <template #body="{ data }: ColumnData">
          <IsTestModeTag v-if="data.isTestMode" />
        </template>
      </Column>

      <template v-for="column in selectedColumns" :key="column.id">
        <Column
          v-if="column.id === 'app'"
          :field="column.id"
          :header="column.label"
          sortable
          sort-field="app.name"
          filter-field="app.id"
          :style="{ 'min-width': '200px' }"
          class="static-column"
        >
          <template #body="{ data }: ColumnData">
            <AppChip
              v-if="data.app"
              :author="data.app.author"
              :name="data.app.name"
              :icon-url="data.app.iconUrl"
              :store="data.store"
              wrap
            />
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'deviceType'"
          :field="column.id"
          :header="column.label"
          sortable
          :style="{ 'min-width': '100px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            {{ prettifyDeviceType(data.deviceType) }}
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'countries'"
          :field="column.id"
          :header="column.label"
          :style="{ 'min-width': '120px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            <div v-if="data.countries.length > 4">
              {{ data.countries.length }} countries
            </div>
            <div v-else class="align-item-center flex justify-center gap-2">
              <VdCountryChip
                v-for="countryCode of data.countries"
                :key="countryCode"
                :code="countryCode"
                :show-label="false"
                show-tooltip
              />
            </div>
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'status'"
          :field="column.id"
          :header="column.label"
          sortable
          :sort-field="campaignStatusSort"
          :style="{ width: '50px' }"
          class="static-column p-0"
        >
          <template #body="{ data }: ColumnData">
            <div v-if="data.status" class="flex items-center justify-center">
              <CampaignStatusChip :value="data.status" show-tooltip />
            </div>
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'todaySpendProgression'"
          :field="column.id"
          :header="column.label"
          sortable
          sort-field="todaySpend"
          :style="{ 'min-width': '180px' }"
          class="static-column"
        >
          <template #body="{ data }: ColumnData">
            <div class="w-full">
              <div>
                {{ prettifyCurrency(data.todaySpend) }}
              </div>
              <ProgressBar
                v-tooltip="
                  `Today's spend progression:\n${prettifyCurrency(
                    data.todaySpend,
                  )} / ${prettifyCurrency(data.totalDailyBudget)}`
                "
                :value="data.todaySpendProgression"
                :show-value="false"
                class="mt-2 w-full"
                style="height: 6px"
                :pt="{
                  value: {
                    class: { 'bg-green-500': data.todaySpendProgression > 95 },
                  },
                }"
              />
            </div>
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'hasAutomaticAdsManagement'"
          :field="column.id"
          :header="column.label"
          :style="{ 'min-width': '100px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            {{ data.hasAutomaticAdsManagement ? "Automatic" : "Manual" }}
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'campaignType'"
          :field="column.id"
          :header="column.label"
          :style="{ 'min-width': '100px' }"
          class="static-column text-center"
        >
          <template #body="{ data }: ColumnData">
            <CampaignTypeTag
              :campaign-type="data.type"
              :campaign-roas-type="data.roasType"
              no-icon
            />
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'periodSpendProgression'"
          :field="column.id"
          sortable
          sort-field="billableSpend"
          :style="{ 'min-width': '180px' }"
          class="static-column"
        >
          <template #header>
            <div class="flex items-center gap-1">
              {{ column.label }}
              <i
                v-tooltip="{ value: BUDGET_INFO_TEXT }"
                class="fa fa-info-circle text-primary-300"
              />
            </div>
          </template>

          <template #body="{ data }: ColumnData">
            <div class="relative pb-4">
              <span v-if="!isReportsLoading">{{
                prettifyCurrency(data.billableSpend ?? 0)
              }}</span>

              <span v-else>
                <Skeleton width="3rem" height="1.2rem" />
              </span>

              <Skeleton
                class="pointer-events-none !absolute top-7 z-10 transition-opacity duration-200"
                :class="{
                  'opacity-100': isReportsLoading,
                  'opacity-0': !isReportsLoading,
                }"
                width="100%"
                height="6px"
              />

              <ProgressBar
                v-tooltip="
                  `Period spend progression:\n${prettifyCurrency(
                    data.billableSpend,
                  )} / ${prettifyCurrency(data.periodDailyBudget)}`
                "
                :value="isReportsLoading ? 0 : data.periodSpendProgression"
                :show-value="false"
                class="absolute top-7 w-full transition-opacity duration-500"
                :class="{
                  'opacity-0': isReportsLoading,
                  'opacity-100': !isReportsLoading,
                }"
                :pt="{
                  value: {
                    class: { 'bg-green-500': data.periodSpendProgression > 95 },
                  },
                }"
                style="height: 6px"
              />
            </div>
          </template>
        </Column>

        <Column
          v-else-if="column.id === 'adPlacement'"
          :field="column.id"
          sortable
          :style="{ 'min-width': '100px' }"
          class="static-column text-center"
          header="Placement"
        >
          <template #body="{ data }: ColumnData">
            <div v-if="data.adPlacement">
              {{ prettifyAdPlacement(data.adPlacement, { minified: true }) }}
            </div>
          </template>
        </Column>

        <Column
          v-else
          :field="column.id"
          sortable
          :style="{ 'min-width': column.minWidth }"
        >
          <template #header>
            <div v-tooltip.top="column.description" style="position: relative">
              {{ column.label }}
            </div>
          </template>
          <template #body="{ data }: ColumnData">
            <ReportingTableColumn
              :format="column.format"
              :value="(data[column.id] as number)"
              :loading="isReportsLoading"
              :in-error="isReportsError"
            />
          </template>
        </Column>
      </template>

      <Column
        :style="{ 'width': '4rem', 'max-width': '4rem', 'padding': 0 }"
        frozen
        align-frozen="right"
        class="static-column"
        :class="[{ 'column-right-raised': !hasScrollReachedRight }]"
      >
        <template #body="{ data }: ColumnData">
          <CampaignDetailsMenu
            :campaign-id="data.id"
            :campaign-status="data.status"
            :is-test-mode-campaign="data.isTestMode"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
