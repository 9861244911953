import { FilterMatchMode } from "primevue/api";
import { z } from "zod";

import type { BrandingCampaignsListRow } from "./types";

import type { FilterConfig } from "@/components/business/ListFilters/types";
import usePermissions from "@/composables/use-permissions";
import { useStateFilters } from "@/composables/use-state-filters";
import { CampaignStatus, DeviceType } from "@/graphql";
import { booleanField } from "@/utils/filters/boolean-filters";
import { CONTAINS_ALL, filterArray } from "@/utils/filters/common";

const globalFiltersKeys = ["name", "appRawId", "app.name"];

const brandingCampaignsListFiltersConfig = {
  global: {
    id: "global",
    title: "Search",
    initialValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
    schema: {
      field: z.object({ value: z.string().nullable(), matchMode: z.literal(FilterMatchMode.CONTAINS) }),
      multi: false,
      encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["CONTAINS"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.CONTAINS }),
    },
    inputComponent: "SearchFilterInput",
    tagValueComponent: null,
    isAlwaysDisplayed: true,
  },
  status: {
    id: "status",
    title: "Status",
    initialValue: { value: [], matchMode: FilterMatchMode.IN },
    schema: {
      field: z.object({ value: z.array(z.nativeEnum(CampaignStatus)), matchMode: z.literal(FilterMatchMode.IN) }),
      multi: true,
      encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.IN }),
    },
    inputComponent: "StatusFilterInput",
    tagValueComponent: "StatusFilterTag",
    isAlwaysDisplayed: false,
  },
  countries: {
    id: "countries",
    title: "Countries",
    initialValue: { value: [], matchMode: CONTAINS_ALL },
    schema: {
      field: z.object({ value: z.array(z.string()), matchMode: z.literal(CONTAINS_ALL) }),
      multi: true,
      encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: CONTAINS_ALL }),
    },
    inputComponent: "CountriesFilterInput",
    tagValueComponent: "CountryFilterTag",
    isAlwaysDisplayed: false,
  },
  deviceType: {
    id: "deviceType",
    title: "Device type",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: {
      field: z.object({ value: z.nativeEnum(DeviceType).nullable(), matchMode: z.literal(FilterMatchMode.EQUALS) }),
      multi: false,
      encode: (obj: { value: string | null; matchMode: typeof FilterMatchMode["EQUALS"] }) => obj.value,
      decode: value => ({ value, matchMode: FilterMatchMode.EQUALS }),
    },
    inputComponent: "DeviceTypeFilterInput",
    tagValueComponent: "DeviceTypeFilterTag",
    isAlwaysDisplayed: false,
  },
  isTestMode: {
    id: "isTestMode",
    title: "Test mode",
    initialValue: { value: null, matchMode: FilterMatchMode.EQUALS },
    schema: booleanField,
    inputComponent: "IsTestModeFilterInput",
    tagValueComponent: "IsTestModeFilterTag",
    isAlwaysDisplayed: false,
    visibility: () => {
      const { hasCampaignTestModeWritePermission } = usePermissions();
      return hasCampaignTestModeWritePermission.value;
    },
  },
  platforms: {
    id: "platforms",
    title: "Platforms",
    initialValue: { value: [], matchMode: CONTAINS_ALL },
    schema: {
      field: z.object({ value: z.array(z.string()), matchMode: z.literal(CONTAINS_ALL) }),
      multi: true,
      encode: (obj: { value: string[]; matchMode: typeof FilterMatchMode["IN"] }) => obj.value,
      decode: value => ({ value, matchMode: CONTAINS_ALL }),
    },
    inputComponent: "PlatformsFilterInput",
    tagValueComponent: "PlatformsFilterTag",
    isAlwaysDisplayed: false,
  },
} satisfies Record<string, FilterConfig>;

/**
 * Use filters composable for branding campaigns list
 */
export function useFilters() {
  const { filters, clear } = useStateFilters(brandingCampaignsListFiltersConfig);

  function filterRows(rows: BrandingCampaignsListRow[]) {
    return filterArray(filters.value, rows, globalFiltersKeys);
  }

  return {
    filters,
    config: brandingCampaignsListFiltersConfig,
    clear,
    filterRows,
  };
}
