import { type MaybeRef, useStorage } from "@vueuse/core";
import { type Ref, computed, unref } from "vue";

type Column = {
  id: string;
  label: string;
  displayedByDefault?: boolean;
};

export default function usePersistentColumns<T extends Column>(
  storageKey: string,
  columns: MaybeRef<T[]>,
): { selectedColumns: Ref<T[]> } {
  const allColumns = unref(columns);

  const defaultColumns = Object.fromEntries(allColumns.map(currentValue => [currentValue.id, 0]));

  const columnPositionsById = useStorage<Record<string, number>>(storageKey, defaultColumns);

  const allColumnIds = new Set(allColumns.map(({ id }) => id));
  const storedColumnsIds = new Set(Object.keys(columnPositionsById.value));
  /* if there are columns in the current columns that are not in the storage, add them */
  for (const columnId of allColumnIds) {
    if (!storedColumnsIds.has(columnId)) {
      columnPositionsById.value[columnId] = 0;
    }
  }
  /* if there are columns in the storage that are not in the current columns, remove them */
  for (const columnId of storedColumnsIds) {
    if (!allColumnIds.has(columnId)) {
      delete columnPositionsById.value[columnId];
    }
  }

  const selectedColumns = computed({
    get: () =>
      /** return all stored columns ordered by positions removing the hidden ones */
      allColumns
        .map(column => ({ ...column, position: columnPositionsById.value[column.id] }))
        .filter(column => column.position !== -1)
        .sort((a, b) => a.position - b.position),
    set: (selection) => {
      /** set the position of selected columns */
      for (const [index, column] of selection.entries()) {
        columnPositionsById.value[column.id] = index;
      }
      /** set the position to -1 for all hidden columns */
      const selectedColumnIds = selection.map(({ id }) => id);
      const hiddenColumnIds = [...allColumnIds.values()].filter(o => !selectedColumnIds.includes(o));
      for (const columnId of hiddenColumnIds) {
        columnPositionsById.value[columnId] = -1;
      }
    },
  });

  return { selectedColumns };
}
